// LoginManager - 

import './css/LoginManager.css';
/*
import { 
    CognitoIdentityProviderClient, 
    InitiateAuthCommand, 
    GlobalSignOutCommand,
    RespondToAuthChallengeCommand} from "@aws-sdk/client-cognito-identity-provider"; 
*/

import  DBCache  from './DBCache'; 
//import  CommonValidations  from './Validations'
import  HyozanForm from './HyozanForm';

import { useEffect, useState } from 'react';

import { useAppContext } from './Context';
/*
 * import { refreshAuthTokens } from 'aws-amplify/auth/cognito';
*/

function verifyPasswordRules(password) {
  const hasUppercase = /[A-Z]/;
  const hasLowercase = /[a-z]/;
  const hasNumeric = /[0-9]/;
  const hasSpecialChar = /[!@$%^&()_+\-=[\]{};:'"\\|,.<>/?]/;  // Special characters allowed (excluding * and #)
  const hasSQLUnsafeChars = /[\\*#'";]/;  // SQL unsafe characters to exclude
    
  // Check each requirement
  const containsUppercase = hasUppercase.test(password);
  const containsLowercase = hasLowercase.test(password);
  const containsNumeric = hasNumeric.test(password);
  const containsSpecialChar = hasSpecialChar.test(password);
  const containsSQLUnsafeChars = hasSQLUnsafeChars.test(password);
  const lengthCheck = (password.length > 8);
  
  // Combine checks into a single result
  const isValid = containsUppercase && containsLowercase && containsNumeric && containsSpecialChar && !containsSQLUnsafeChars && lengthCheck;
  
  return isValid;
}

export default function LoginManager(){

  // This component has heavy dependency on cognito. TODO - make it cloud agnostic

  const [formData, setFormData]     = useState({emailaddress:'', password:'', repassword:''});
  const [loginState, setLoginState] = useState('INITIAL');
  const [loginData, setLoginData]   = useState(null);
  const { loginToken, 
      setLogin, 
      logout,
      addStatusMessage}             = useAppContext();
  const db = new DBCache();

  const handleChange = (e) => {
    const {name, value } = e.target;
    setFormData({...formData, [name]:value});
  }

  async function logoutHandler() {
    console.log("Logout @", Date.now());
    await db.logout();
    logout();

  }
  async function handleSubmit (vals) {  
    try{
      switch(loginState) {
        case 'INITIAL':
          console.log("Login", vals)
          const response = await db.login(vals.emailaddress, vals.password);
          switch (response) {
            case 'success':
              const userVals = {
                login: true,
                userName : vals.emailaddress,
                IdToken: 'PUMPKIN',
                AccessToken: 'ACCESS',
                RefreshToken: 'REFRESH',
                TokenType: 'TTT',
                ExpiryTime: Date.now() + (30 * 1000),
              };
              setLogin(userVals);
              db.setUserVals(userVals);
              break;
            case 'needreset':
              setLoginState('FORCERESET');
              break;
            default:
              window.alert('Login Failed');
              break;
          }
      }
    }catch (err){
      console.log(err);
      addStatusMessage("Login failed",'error');
    }
  };

  const handleCancel = (e) => {console.log("cancel")};



  // Various fields structures.

  const emailEntryField ={
            Label: 'email address',
            Explanation: 'Enter your corporate email address. ',
            InputType: 'INPUT',
            // onChange: handleChange,
            Prompt: {
              Type: 'INFO',
              Label: 'Please enter your registered email address'
            },
            Value: formData.emailaddress,
            ID: 'emailaddress',
            InitValue: 'your.name@your.company.com'
  };
  const emailDisplayField ={
            Label: 'email address',
            Explanation: 'Change the password for this email address',
            InputType: 'STATICTEXT',
            // onChange: handleChange,
            Prompt: {
              Type: 'INFO',
              Label: 'Please enter your registered email address'
            },
            Value:  formData.emailaddress,
            ID: 'emailaddress',
            InitValue: 'your.name@your.company.com'
  };
  const passwordField = {
            Label: 'Password',
            Explanation: 'Enter your password',
            InputType: 'PASSWORD',
            // onChange: handleChange,
            Prompt: {
              Type: 'INFO',
              Label: 'Please enter your password'
            },
            Value: formData.password,
            ID: 'password',
            InitValue: '**********'
  };  
  const reenterPasswordField = {
                  Label: 'Repeat Password',
                  Explanation: 'Re-enter your password',
                  InputType: 'PASSWORD',
                  // onChange: handleChange,
                  Prompt: {
                    Type: 'INFO',
                    Label: 'Please re-enter your password'
                  },
                  Value: formData.repassword,
                  ID: 'repassword',
                  InitValue: '**********'
  };
  const mfaEntryField = {
                  Label: 'Enter your MFA Password',
                  Explanation: 'Your second factor (could be a TOTP or an SMS or something blowin in the wind...)',
                  InputType: 'PASSWORD',
                  // onChange: handleChange,
                  Prompt: {
                    Type: 'INFO',
                    Label: 'Please re-enter your password'
                  },
                  Value: formData.mfaPassword,
                  ID: 'mfapassword',
                  InitValue: '**********'
  };

  var vals;
  switch(loginState){
    case 'INITIAL':
        vals={
            FormName: 'Login to Hajime',
            Fields:[emailEntryField,passwordField],
            onSubmit: handleSubmit,
            onCancel: handleCancel,
            SubmitText: "Signon"
        };
        break;
    case 'FORCERESET':
        vals={
              FormName: 'Reset password to continue',
              Fields:[emailDisplayField,passwordField, reenterPasswordField],
              onSubmit: handleSubmit,
              onCancel: handleCancel,
              SubmitText: "Signon"
        };
        break;  
    default:
      console.log('Internal Error- invalid login state');
  }

  if (loginToken.login === true){
    return (
      <div className='login-icon-holder'>
        <p className='login-user-name-view'> {loginToken.userName} </p>
        <div className='logout-button-holder'>
          <button onClick={logoutHandler} className='hyozan-button secondary'> logout </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className='modal-backdrop'>
        <div className='login-form-modal'>
          <HyozanForm FormVals={vals} />
        </div>
      </div>
    );
  }
}
