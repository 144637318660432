import './css/BarChart.css';

export default function BarChart({data,stackNames,width,height}){

  const VSCALE = 0.80;  // The tallest bar will be 75% of the overall height
  const HSCALE = 0.90;
  const BARSIZE= 0.60;  // Bar occupies 60% , 40% gap
  const XOFFSET = width * 0.05;
  const YOFFSET = height * 0.90; // To draw the bars from below
  const LABELOFFSET = 10;
  const LEGENDWIDTH = 150;
  const LEGENDOFFSET = width - LEGENDWIDTH;
  const LEGENDYINIT = 30;
  const LEGENDLINEHIGHT = 20;
  const TICKWIDTH = 5;

  var maxValue = Number.MIN_VALUE;
  var minValue = Number.MAX_VALUE;
  var maxBar   = Number.MIN_VALUE;
  


  // First figure out the vertical scales
  // find the max and min values in the data set
  data.map((d) => {
    var total = 0;
    d.vals.map((v) => {
      total += v.value;
      if(v.value > maxValue)
        maxValue = v.value;
      if(v.value < minValue)
        minValue = v.value;
    })
    if(total > maxBar)
      maxBar = total;
  });
  console.log(`Max ${maxValue} Min ${minValue} Maxbar ${maxBar}`);
  
  // create the scaling factor for the bars
  const vertScale = (height * VSCALE ) / maxBar;
  const numBars   = data.length;
  const hScale    = (width * HSCALE) / numBars;
  const barWidth  = hScale * BARSIZE;
  const barGap    = hScale * (1 - BARSIZE);

  //console.log(`vertscale, numbars, hscale, barwidth, bargap`);
  //console.log(vertScale, numBars, hScale, barWidth, barGap);

  // Now create the data structure to hold the individual 
  // rectangles that make up the bar chart
  const bars = [];
  data.map((d,barNum) => {
    var ySoFar = YOFFSET;
    d.vals.map((v,valNum) => {
      const bar = {
        x: XOFFSET + (barNum * (barWidth+barGap)),
        y: ySoFar  - (v.value * vertScale),
        dx : barWidth,
        dy : (v.value * vertScale),
        color : stackNames[v.stackName].color,
      };
      bars.push(bar);
      ySoFar -= v.value * vertScale;
    });
  });

  // debug: print the rects
  //bars.map((b)=>{console.log(b)})

  const labels=[];
  data.map((d,barNum)=>{
    const label = {
     text: d.xLabel,
     x : XOFFSET + (barNum * (barWidth + barGap)),
     y : YOFFSET + LABELOFFSET,
    };
    labels.push(label);
  });

  const legends=[];
  Object.keys(stackNames).map((t,num)=>{
    legends.push({
      text: stackNames[t].legend,
      color: stackNames[t].color,
      x: LEGENDOFFSET,
      y: LEGENDYINIT + (num * LEGENDLINEHIGHT),
    });
  });

  // Y axis management
  // Find the nearest 'nice' number
  function roundUp(num){
    if(num < 10)
      return 10;
    else if (num < 100)
      return 100;
    else if (num >= 100 && num < 1000)
      return(Math.ceil(num / 100) * 100);
    else if (num >= 1000 && num < 10000)
      return(Math.ceil(num / 1000) * 1000);
    else
      return num;
  }

  const yMax = roundUp(maxBar);
  const tickSize = yMax / 10;
  const ticks = [];
  for (let i=0;i <= yMax ; i+= tickSize)
    ticks.push(
      {
        y:YOFFSET - (i * vertScale),
        val: i,
      }
    );


  return(
    <div>
      <svg version="1.1"
        width={width} height={height}
        xmlns="http://www.w3.org/2000/svg">

        {/** DEBUG ONLY : draw the bounding rectangle }
          <rect x={0} y={0} width={width} height={height} stroke="black" fill="none"/>
          <rect x={XOFFSET} y={height-YOFFSET} width={width-(XOFFSET*2)} height={YOFFSET - (height-YOFFSET)} stroke="red" fill="none"/>
        **/}

        {/** Draw the bars **/}
        {
          bars.map((b,index)=>{
            return(<rect 
              x={b.x}
              y={b.y}
              width = {b.dx}
              height = {b.dy}
              fill = {b.color}
              key = {index}
            />)

          })
        }


        {/** Draw the X Axis **/}
        <line x1={XOFFSET} y1={height - (height - YOFFSET)} x2={width-XOFFSET} y2={height - (height - YOFFSET)} stroke="black" strokeWidth="2"/> 

        {/** Draw the Y Axis **/}
        <line x1={XOFFSET} y1={height - YOFFSET} x2={XOFFSET} y2={height - (height - YOFFSET)} stroke="black" strokeWidth="2"/>

        {/** Draw the X axis labels **/}
        {
          labels.map((l,index)=>{
            return(
              <text key={index} x={l.x} y={l.y} className='label-text' transform={`rotate(60, ${l.x}, ${l.y})`}>{l.text}</text>
              )
          })
        }

        {/** Draw the Y axis ticks **/}
        {
          ticks.map((t,index) => {
            return(
              <g key={index}>
                <line 
                  key={'q'+ index} 
                  x1 = {XOFFSET-TICKWIDTH} 
                  y1 ={t.y} x2={XOFFSET} 
                  y2={t.y} 
                  stroke="darkgray" 
                  strokeWidth="3" />
                <text 
                  alignmentBaseline='middle' 
                  x={XOFFSET - 30}
                  y={t.y}
                  className='tick-text'
                  key={'t'+index}>
                    {t.val}
                </text>
              </g>
            )
          })
        }

        {/** Draw the Y axis labels **/}

        {/** Draw the legend **/}
        <rect x={LEGENDOFFSET - 5} y={LEGENDYINIT - 10} width={LEGENDWIDTH + 5} height={200} stroke="gray"  fill="none"/>
        {
          legends.map((l,index) => {
            return(
              <g key={'g'+index}>
                <text alignmentBaseline='hanging' key={'t'+index} x={l.x} y={l.y} className='legend-text'>{l.text}</text>
                <rect key={'r'+index} x={l.x + 120} y={l.y} height={LEGENDLINEHIGHT - 5} width={20} fill={l.color} />
              </g>
            )
          })
        }

      </svg>
    </div>
  );
}
