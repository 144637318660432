/***
 * 
 * Common encryption routines that will be shared across hyozan
 *
 * 
 */

import forge from 'node-forge';
import { Buffer } from 'buffer';

export function encrypt(plaintext, keys){

    /****
     * 
     * Perform AES 256 CBC encryption, base64 encode
     * the result and return
     */

    const cipher = forge.cipher.createCipher('AES-CBC',keys.sessionKey);
    cipher.start({iv:keys.initVector});
    cipher.update(forge.util.createBuffer(plaintext));
    cipher.finish();
    const encrypted            = cipher.output.getBytes();
    // console.log('cipher done')
    // console.log(encrPubKey.toHex())
    const encrB64         = forge.util.encode64(encrypted);

    return encrB64;
}

export function makeHash(plaintext,salt){
    /**
     * Assume the salt and plaintext are strings. input should be appropriately
     * stringified before calling this function.
     * 
     * Salt is prepended to the plaintext.
     * 
     */
    //console.log('attempting hash for p:' + plaintext + ': with salt :' + salt);
    const input = salt + plaintext;
    //console.log(input);
    var   md    = forge.md.sha512.create();
    md.update(input);
    const hash  = md.digest().toHex();
    //console.log(hash);
    return hash;
}

export function decrypt(encrypted,keys){
    const decipher = forge.cipher.createDecipher('AES-CBC',keys.sessionKey);
    decipher.start({iv: keys.initVector});
    decipher.update(forge.util.createBuffer(encrypted));
    const decResult = decipher.finish();
    if (!decResult)
        throw new Error('Decryption failure');

    const plaintext = decipher.output;
    return plaintext;
}

export function makeOTP(length = 6) {
  let otp = '';
  for (let i = 0; i < length; i++) {
    otp += Math.floor(Math.random() * 10); 
  }
  return otp;
}

export function encode64(text) {
  const utf8Text = new TextEncoder().encode(text);
  return btoa(String.fromCharCode(...utf8Text));
}

export function decode64(encodedText) {
  return Buffer.from(encodedText, 'base64').toString('utf-8');
}
