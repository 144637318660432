import './css/UserManager.css'

import { useEffect, useState } from 'react';

import DBCache  from            './DBCache'; 
import CommonValidations  from  './Validations'
import HyozanForm from          './HyozanForm';
import Waiter from              './Waiter';

export default function UserManager(){
  
  const [showAddUser, setShowAddUser] = useState(false);
  const [userList, setUserList]       = useState(null);
  const [departments, setDepartments] = useState(null);
  const [roles, setRolesList]         = useState(null);
  const [waiting, setWaiting]         = useState(false);
   
  const db = new DBCache();
  const cv = new CommonValidations();

  useEffect(dbFetchUserData,[]);

  function dbFetchUserData() {
    setWaiting(true);
    db.getUserList((list)=>{setWaiting(false);setUserList(list)});
    db.getDepartmentList((list)=>{setWaiting(false);setDepartments(list)});
    db.getRolesList((list)=>{setWaiting(false);setRolesList(list)});
  }

  function AddUserForm(){ // TODO: Fix bug where department / roles list have not loaded yet
    const [formData, setFormData] = useState({
                      UserEmail:'', 
                      description:'',
                      department:'',
                    });

    const handleChange = (e) => {
      const {name, value } = e.target;
      setFormData({...formData, [name]:value});
    };

    const userCreationResultHandler = (res) => {
      console.log("Got result", res);
      if (res){
        window.alert("User creation successful \n the temporary password is\n"+res.tempPassword+"\n\n");
      }
      setWaiting(false);
      setShowAddUser(false);
    };

    function handleSubmit(e){
      
      console.log(e)   ; // TODO - Add validations
      db.addUser(e, userCreationResultHandler);
      setWaiting(true);
           
    }
    const handleCancel = (e) => {
      setFormData({userName:'', description:''}); // reset values after submission
      setShowAddUser(false);
    }

    
    const vals={
      FormName: 'Add new user',
      Fields: [
        {
          Label: 'Email ID',
          Explanation: 'Enter a valid email ID. This will be used for all contact with this user. This will also be the user ID, and be used for login',
          InputType: 'INPUT', // TODO - Need an email ID entry field
          onChange: handleChange,
          Prompt: {
            Type: 'INFO',
            Label: 'Please enter a valid email ID'
          },
          Value: formData.emailID,
          ID: 'emailID',
          InitValue: 'username@company.com'
        },
        {
          Label: 'Name',
          Explanation: 'Name of the user, for identification purposes. This will not be used for login etc.',
          InputType: 'INPUT',
          onChange: handleChange,
          Prompt: {
            Type: 'INFO',
            Label: 'Please enter a valid name'
          },
          Value: formData.name,
          ID: 'name',
          InitValue: 'firstname lastname'
        },
        {
          Label: 'Mobile phone number',
          Explanation: 'Enter a valid phone number. Prefix with country code in + format. This will be used for all contact with this user and for 2FA',
          InputType: 'INPUT',   // TODO - Need a mobile number format input
          onChange: handleChange,
          Prompt: {
            Type: 'INFO',
            Label: 'Please enter a valid number'
          },
          Value: formData.mobileNumber,
          ID: 'mobilenumber',
          InitValue: '+919912345678'
        },
        {
          Label: 'Description',
          Explanation: 'Information about this user, as required',
          InputType: 'TEXTAREA',
          onChange: handleChange,
          Prompt: {
            Type: 'INFO',
            Label: 'Please input description'
          },
          Value: formData.description,
          ID: 'description',
          InitValue: 'Content creation for vehicle loans department.'
        },
        {
          Label: 'Department',
          Explanation: 'Enter the department code for this user',
          InputType: 'LIST',
          Options: departments ? departments.map(d =>{ return {ID: d.department, Text:d.department}}) : [],
          onChange: handleChange,
          Prompt: {
            Type: 'INFO',
            Label: 'Please enter a valid department code'
          },
          Value: formData.department,
          ID: 'department',
          InitValue: ''
        },
        {
          Label: 'Role',
          Explanation: 'Enter the role for this user',
          InputType: 'LIST',
          Options: roles ? roles.map(d =>{ return {ID: d.roleID, Text:d.roleID}}) : [],
          onChange: handleChange,
          Prompt: {
            Type: 'INFO',
            Label: 'Please enter a valid role '
          },
          Value: formData.role,
          ID: 'role',
          InitValue: ''
        },
      ],
      onSubmit: handleSubmit,
      onCancel: handleCancel,
      SubmitText: 'Create User',
    }

    return (
      roles && departments && <HyozanForm FormVals={vals} />
    )
  }

  const setShowAddUserTrue = () => {console.log(Date.now());setShowAddUser(true)};
  const refreshUserList = () => {db.getUserList(setUserList, true)}; // Force a refresh

  var i = 0;

  return(
    <div className='hyozan-workspace'>
      <div className='hyozan-dashboard'>
        <div className='dashboard-header'> 
          <div className="dashboard-title">
            <p>List of users</p> 
          </div>
          
          <div className='dashboard-spacer'></div>

          <div className='dashboard-controls'>
            <div className="refresh">
              <div title="refresh" onClick={refreshUserList}>
                <img src='refresh.png' alt="refresh"/>
              </div>
            </div>
            <div className="control-button">
              <button title="create user" className='hyozan-button active' onClick={setShowAddUserTrue}> Add User</button>
            </div>
          </div>
        </div>      
        <table className='hyozan-table'>
          <UserListHeader />
          <tbody>
            {userList &&
              userList.map((user) => {return(
                  <UserListRow userVals={user} key={i++} />
              )}) 
            }
          </tbody>
        </table>

        {showAddUser &&
          <div>
            <AddUserForm />
          </div>
        }
      </div>
      {waiting && <Waiter />}
    </div>
  )
}

function UserListHeader(){
  return (
    <thead className='hyozan-table-header'>
      <tr className='hyozan-table-header-tr'>
        <th> Name </th>
        <th> Description </th>
        <th> Department </th>
      </tr>
    </thead>
  );
}

function UserListRow({userVals}){   
  const user = userVals;
  var   tdClass;
  return (
    <tr key={user.UserEmail} className='hyozan-table-row'>
      <td>{user.UserEmail}</td>
      <td>{user.description && user.description.slice(0,40)}</td>
      <td>{user.department? user.department : '?'}</td>
    </tr>
  )
}
