import './css/SankeyChart.css';
import React, { useEffect, useRef } from 'react';

// TODO: move this into a common utility component.
function saturate(colorString){
  const hslMatch = colorString.match(/hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/);
  
  if(hslMatch){
    const hue = hslMatch[1];
    const lightness = hslMatch[3];
    return (`hsl(${hue}, 100%, 40%`);
  }

  return colorString;
}

export default function SankeyChart({data, width, height}){
  const scaleFactor = 0.6;
  const verticalGap = height * 0.05;
  const barWidth = 20;
  const yOffset = 30;
  const xOffset = 50;

  
  const nodes=new Set();
  const sankey={};

  data.map((d)=>{
    nodes.add(d.from).add(d.to);
    });
  //console.log(`got ${nodes.size} nodes`);
  // now compute number of layers
  nodes.forEach((v)=>{
    sankey[v]= {
                nodename:v,
                layer:0,
                root:true,
                leaf:true,
                size:0,
                x:0,
                y:0,
                height: 10,
                displacement: 0,
                color: null,
               };
  });

  // Find the root node(s)
  // just go thru and if anything is 
  // a "to" element, then it cannot be a root
  data.map((d) => {sankey[d.to].root = false;});

  // Find the leaf elements
  // just go thru the data and if any flow
  // has a "from" this element then its not 
  // a leaf
  data.map((d) => {sankey[d.from].leaf = false;});

  // Now find the layer number for each node
  // If the node is root, then the layer is zero.
  // Else, the layer number is one greater than
  // the current layer's number
  // Also, the size of a node is the sum of the 
  // flows _from_ that node.
  data.map((d) => {
    sankey[d.to].layer = sankey[d.from].layer + 1;
    sankey[d.from].size += d.value;
    if(sankey[d.to].leaf)
      sankey[d.to].size += d.value;

    // Also set the color of this node
    // Simple logic is to set the node color
    // equal to the first flow color
    if(sankey[d.to].color === null)
      sankey[d.to].color = saturate(d.color);
  });

  // find the total number of layers. Its basically
  // the highest layer number + 1
  let layers=0;
  Object.keys(sankey).map((node)=>{
    if (sankey[node].layer > layers)
      layers = sankey[node].layer;
  });
  layers ++;

  // Find the maximum node size. This will be used to
  // proportion the height of all elements
  let maxnode = 1;
  Object.keys(sankey).map((node)=>{
    if (sankey[node].size > maxnode)
      maxnode = sankey[node].size;
  });


  // Now compute the coords of each node rectangle
  Object.keys(sankey).map((node)=>{
    sankey[node].x = xOffset + sankey[node].layer * (width/layers);
    sankey[node].height = sankey[node].size * (height/maxnode) * scaleFactor;
  });

  // Adjust the vertical offset of each rectangle
  for(let i=0;i<layers;i++){
    let offset = yOffset;
    let displacement = 0;
    Object.keys(sankey).map((node)=>{
      if(sankey[node].layer === i){
        sankey[node].y = offset ;
        sankey[node].displacement = displacement;
        offset += sankey[node].height + verticalGap;
        displacement += sankey[node].height;
      }
    })
  }

//  console.log(sankey);
//  console.log(`got ${layers} layers`);

  // now build the SVG pathstring for each flow
  const paths = [];

  const controlPoints = []; // Only for debugging

  data.map((d) => { paths.push(makePath(d));});
//  console.log(paths);

  function makePath(flow){
    const startX  = sankey[flow.from].x + barWidth;
    const startY  = sankey[flow.from].y + sankey[flow.to].displacement;
    const endX    = sankey[flow.to].x;
    const endY    = sankey[flow.to].y;
    const ctrl1X  = sankey[flow.from].x + barWidth + (width/layers)*0.2;
    const ctrl1Y  = startY ;
    const ctrl2X  = sankey[flow.to].x - (width/layers)*0.2;
    const ctrl2Y  = endY;

    const lstartX = startX;
    const lstartY = startY + sankey[flow.to].height;
    const lendX   = endX;
    const lendY   = endY + sankey[flow.to].height;
    const lctrl1X = startX + (width/layers)*0.2;
    const lctrl1Y = lstartY;
    const lctrl2X = endX - (width/layers)*0.2;
    const lctrl2Y = lendY;

    const moveto  = `M ${startX} ${startY} `;
    const curve1  = `C ${ctrl1X} ${ctrl1Y} ${ctrl2X} ${ctrl2Y} ${endX} ${endY} `;
    //const curve1  = `L ${endX} ${endY} `;
    const line    = `L ${lendX} ${lendY} `;
    const curve2  = `C ${lctrl2X} ${lctrl2Y} ${lctrl1X} ${lctrl1Y} ${lstartX} ${lstartY} `;
    //const curve2  = `L ${lstartX} ${lstartY} `;
    const finish  = `Z `;

    const pathstring = moveto + curve1 + line + curve2 + finish;

    // Only for debugging
    const controls = {
      c1: {x: ctrl1X, y:ctrl1Y},
      c2: {x: ctrl2X, y:ctrl2Y},
      c3: {x: lctrl1X, y:lctrl1Y},
      c4: {x: lctrl2X, y:lctrl2Y},
    };
    controlPoints.push(controls);


    return pathstring;

  }

  // create the labels
  const labels = [];
  Object.keys(sankey).map((d) => {labels.push(addLabel(sankey[d]))});

  function addLabel(node){
    const fontwidth = 10;
    const text = node.nodename;
    const value = node.size.toLocaleString();
    const x = node.x - barWidth/2;
    const y = node.y + node.height/2;
    const tx = x - 5;
    const ty = y - 15;
    const tw = text.length * fontwidth;
    const th = 40; // hardcode now
    return({text,x,y,tx,ty,tw,th,value});
  }

  return(
    <>
      <svg version="1.1"
        width={width} height={height}
        xmlns="http://www.w3.org/2000/svg">
        {
          Object.keys(sankey).map((node,index)=>
            {
              return(<rect 
                        x={sankey[node].x} 
                        y={sankey[node].y} 
                        width = {barWidth} 
                        height = {sankey[node].height} 
                        fill={sankey[node].color}
                        key={index}
                    />)
            })
        }
        {
          paths.map((p,index) => {
              return(<path key={index} d={p} fill={data[index].color}  />)
          })
        }
        {/**
          controlPoints.map((c) => {
            return(
              <g>
              <circle cx={c.c1.x} cy={c.c1.y} r="3" fill="blue" />
              <circle cx={c.c2.x} cy={c.c2.y} r="3" fill="green" />
              <circle cx={c.c3.x} cy={c.c3.y} r="3" fill="orange" />
              <circle cx={c.c4.x} cy={c.c4.y} r="3" fill="yellow" />
              </g>
            )
          })
        **/}
        {
          labels.map((d,index) => {
            return(
              <g key={index}>
                <rect x={d.tx} y={d.ty} width={d.tw} height={d.th} fill="#fff" fillOpacity="0.5" rx="5"/>
                <text x={d.x} y={d.y} fontSize="12">
                  {d.text}
                  <tspan x={d.x } dy="14" fontSize="14" fontWeight="bold">
                    {d.value}
                  </tspan>
                </text>
              </g>
            )
          })
        }
      </svg>
    </>
  )
}
