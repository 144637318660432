import './css/LeadList.css'

import { useEffect, useState } from 'react';

import  DBCache  from './DBCache'; 
import  CommonValidations  from './Validations'
import  HyozanForm from './HyozanForm';
import  TableHeader from './TableHeader';
import  CallWindow  from './CallWindow';
import  LeadManager from './LeadManager';
import  Waiter      from './Waiter';

import { useAppContext } from './Context';

export default function LeadList({initVals}){
  const { addStatusMessage } = useAppContext();

  const [leadList, setLeadList]           = useState(initVals);
  const [leadToManage, setLeadToManage]   = useState(null);
  const [leadSelected, setLeadSelected]   = useState(null);
  const [waiting, setWaiting]             = useState(false);

  const db = new DBCache();
  const cv = new CommonValidations();

  useEffect(dbFetchLeadList,[]);

  function dbFetchLeadList() {
    if(initVals === null){
      setWaiting(true);
      db.getLeadList(updateLeadList);
    }
  }

  function updateLeadList(leads){
    setWaiting(false);
    if(leads)
      setLeadList(leads);
    else
      window.alert('Unable to fetch leads, please try again');
  }

  const refreshLeadList = () => {
    setWaiting(true);
    db.getLeadList(updateLeadList); 
  };
  let i=0;

  return(
    <div className='hyozan-workspace'>
      {leadToManage == null &&
      <div className='hyozan-dashboard'>
        <div className='dashboard-header'> 
          <div className="dashboard-title">
            <p>Leads</p> 
          </div>
          
          <div className='dashboard-spacer'></div>

          <div className='dashboard-controls'>
            <div className="refresh">
              <div title="refresh" onClick={refreshLeadList}>
                <img src='refresh.png' alt="refresh"/>
              </div>
            </div>
          </div>
        </div>      
        <table className='hyozan-table'>
          <LeadListHeader />
          <tbody>
            {leadList &&
              leadList.map((lead) => {return(
                  <LeadListRow leadVals={lead} key={i++} />
              )}) 
            }
          </tbody>
        </table>      
      </div>
      }

      <div>
        {leadToManage && 
          <div>
            <LeadManager 
              leadVals={leadToManage} 
              closeHandler={()=>{setLeadToManage(null)}}  
            />
          </div> 
        }
      </div>
      {waiting && <Waiter />}
    </div>
  )

  function LeadListHeader(){
    const headers=[
      {
        label:'   ',
        sort: false,
        fieldname:null,
      },
      {
        label: 'Type',
        sort:  true,
        fieldname: 'leadType',
      },
      {
        label: 'Created',
        sort: true,
        fieldname: 'createDate',
      },
      {
        label: 'Status',
        sort: true,
        fieldname: 'disposition',
      },
      {
        label: 'Phone',
        sort: true,
        fieldname: 'leadVals.phone',
      },
      {
        label: 'Name',
        sort: true,
        fieldname: 'leadVals.name',
      },
      {
        label: 'Notes',
        sort: false,
        fieldname: null,
      },
      {
        label: 'Manage',
        sort: false,
        fieldname: null,
      },
    ];

    return(<TableHeader 
              fields={headers} 
              data={leadList} 
              setter={setLeadList} 
          />);
  }


  function LeadListRow({leadVals}){   
    const lead = leadVals;
    return (
      <tr 
        key={lead.leadID} 
        id={lead.leadID} 
        className={lead.leadID === leadSelected ? 'hyozan-table-row selected-row':'hyozan-table-row' }
        onDoubleClick={handleLeadClick}>

        <td>
          <input 
            type='radio'
            checked={lead.leadID === leadSelected}
            onChange={()=>{}}
            onClick={()=>{setLeadSelected(lead.leadID)}}
          />
        </td>
        <td>{lead.leadType}</td>
        <td>{cv.formattedDate(lead.createDate)}</td>
        <td>{lead.disposition ?? "UNKNOWN"}</td>
        <td>{lead.leadVals.phone ?? "-"}</td>
        <td>{lead.leadVals.name ?? "-"}</td>
        <td>{lead.notes?lead.notes.length : "-"}</td>
        <td>{lead.leadID === leadSelected &&
              <button type='button' onClick={()=>{setLeadToManage(lead)}}>Manage</button>
            }
        </td>

      </tr>
    )
  }

  function handleLeadClick(event) {
    const leadreqd = event.target.parentNode.id;

    for (let i=0; i < leadList.length; i++){
      if (leadList[i].leadID === leadreqd){
        setLeadToManage(leadList[i]);
        return;
      }
    }
  }
}

