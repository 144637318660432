// Global class to hold app context and state 

import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [statusMessages, setStatusMessages] = useState([]);
  const [loginToken, setLoginToken] = useState({login:false});
  //const [callWindow, setCallWindow] = useState(false);

  const addStatusMessage = (message, type='info') => {
    const newMessage = {
        messageType: type,
        messageText: message,
        messageTime : Date.now()
    }
    setStatusMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const setLogin = (loginVals) => {
    setLoginToken(loginVals); // TODO - Add some sanity checks to ensure this doesnt destroy the structure
    // Expect this to be like:
    // login: <bool>
    // IdToken: <string>
    // RefreshToken:<string>
    // TokenType:<string>

    // Save this to local storage on login

    localStorage.setItem('hajime-login-tokens', JSON.stringify(loginVals));
  }

  const logout = () => {
    localStorage.removeItem('hajime-login-tokens');
    setLoginToken({login:false});
  }

  const contextObject = { 
    statusMessages, 
    addStatusMessage, 
    loginToken, 
    setLogin, 
    logout,
  };
  return (
    <AppContext.Provider value={contextObject}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
