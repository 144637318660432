/*
import { 
    CognitoIdentityProviderClient, 
    InitiateAuthCommand, 
    } from "@aws-sdk/client-cognito-identity-provider"; 
*/

import forge from 'node-forge';


import {encrypt, 
        decrypt, 
        makeHash,
        encode64,
        decode64} from './shared/encryption.mjs';
import { stringToDate } from './shared/validations.mjs';

const TICKER    = 30 * 1000;        // Time interval for refreshing the session etc.

const HANDLER = "https://console.hajime.hyozan.com/default/hajime-console-handler";
const APIROOT = HANDLER;
const langlist = ['en','ja','ta','hi']; // ISO 639 two letter code

class  DBCache {
  static initialized = false;
  static siteList = null;
  static TIMER_DELAY = 30*1000; // Every 30 seconds...
  static jobList = null;
  static HANDLER = HANDLER;
  static userVals = {
                login: false,
                userName: '',
                IdToken: '',
                AccessToken: '',
                RefreshToken: '',
                TokenType: ''
            };
  static TheProfile = {
      Locale          : langlist[0],
      Initial         : false,
      Connected       : false,
      LoginLevel      : 'prelogin',
      CustomerID      : '',
      Crypto          : 
      {
          publicKey   : null,
          sessionKey  : null,
          sessionID   : null,
          initVector  : null,
          sessionExpiry: null,
          loginToken  : null,
      },
      DeviceInformation : {
          deviceID : '',
      },
  };
  static userList = null;
  static rolesList = null;
  static departmentsList = null;
  static SessionID = null;
  static CallToken    = null;


  constructor() {
    if(DBCache.initialized !== true){
      this.Initialize();
    }
    DBCache.initialized = true;
  }

  Initialize(){
    //console.log('Setting interval');
    setInterval(() => {this.Ticker();}, DBCache.TIMER_DELAY);
  }

  Ticker(){
    //console.log("Ticker says tiktok @ ", Date.now());
    if(DBCache.userVals.login){

      const sessionTimeLeft = DBCache.userVals.ExpiryTime - Date.now();
      //console.log('login true, expiring in ' +   Math.floor(sessionTimeLeft / 1000) + ' seconds');
      
      if(sessionTimeLeft < DBCache.TIMER_DELAY * 2)
        this.renewSession();
    }
  }

  async renewSession(){
    /*
    const config = {region: 'ap-southeast-1'};
    const client = new CognitoIdentityProviderClient(config);
    const input = {
      AuthFlow : 'REFRESH_TOKEN_AUTH',
      AuthParameters: {
        REFRESH_TOKEN : DBCache.userVals.RefreshToken,
      },
      ClientId: '75l736gosanj06s48rv8nghg7t'
    };

   const command = new InitiateAuthCommand(input);
   const response = await client.send(command);
                    
   //console.log("response: " , response);  
                    
   // TODO :  Now look at the response and handle appropriately
   if(response && response.AuthenticationResult ) {
      DBCache.userVals.AccessToken = response.AuthenticationResult.AccessToken;
      DBCache.userVals.IdToken     = response.AuthenticationResult.IdToken;
      DBCache.userVals.ExpiryTime  = response.AuthenticationResult.ExpiresIn * 1000 +
                                      Date.now();     // Updated the expiryTime

      //console.log("updated tokens :");
      //console.log(DBCache.userVals);
    }
    */
  }

  whatsMyName(){
    return (DBCache.initialized ?  "Heisenberg" : "Walt");
  }

  setUserVals(vals){
    DBCache.userVals = vals;
  }

  setHeaders(xhr, tokens){
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Authorization', `Bearer ${DBCache.userVals.IdToken}`);
  }

  serverFetch(request, handler) { // Utility function to not have to repeat the XHR in every function
    if (DBCache.userVals == null || ! DBCache.userVals?.login) 
    {
      console.log("Not logged in - aborting server request");
      console.log( DBCache.userVals);
      handler(null);
      return;
    }

    // Add credentials to the request
    request["userInfo"] = DBCache.userVals;
    
    const xhr = new XMLHttpRequest();

    xhr.onload = () => handler(xhr);
    xhr.open("POST", DBCache.HANDLER, true);
    this.setHeaders(xhr);
    try{
      xhr.send(JSON.stringify(request));
    }catch(err){
      console.error(err);
      handler(null);
    }

  }

  getSiteList(callbackFn, force=false) {
    // console.log(DBCache.userVals); // DEBUG ONLY

    if (DBCache.userVals == null || ! DBCache.userVals?.login) 
      return;

    if (DBCache.siteList && !force) {
      callbackFn(DBCache.siteList);
    }
    else
    {
      const request = {
        command: 'getSites',
      }

      function responseHandler(response){
        if((response.status >= 200) && (response.status < 300)){
          DBCache.siteList = JSON.parse(response.responseText);
          callbackFn(DBCache.siteList);
        } else {
          console.error(`request failed with status ${response.status}`);
          callbackFn(null);
        }
      }
      this.serverFetch(request,responseHandler);
    }
  }

  createNewSite(siteInfoParam, siteCreationCallback) {
    // console.log("Trying to create siteInfo" + JSON.stringify(siteInfoParam));

    const xhr = new XMLHttpRequest();
    xhr.onload = setSiteCreationRetval;
    xhr.open("POST",DBCache.HANDLER, true);
    const reqBody = {
      command : 'createSite',
      userInfo : DBCache.userVals,
      siteInfo :  { 
                    siteName : siteInfoParam.siteName, 
                    siteDescription: siteInfoParam.siteDescription,
                    gitURL: siteInfoParam.gitURL,
                    numStaging: siteInfoParam.numStaging,
                  }      
    }
    this.setHeaders(xhr);
    console.log(reqBody);
    xhr.send(JSON.stringify(reqBody));

    function setSiteCreationRetval(){
      console.log("received response for create New Site");
      if (xhr.status >= 200 && xhr.status < 300){
        console.log(JSON.parse(xhr.responseText));
        siteCreationCallback({result: 'success', Arn: JSON.parse(xhr.responseText).Arn});
      }
      else {
        console.log("Site creation failed");
        siteCreationCallback({result: 'failed', Arn: null});
      }
    }    
  }

  getUserList(callbackFn, force=false) {
    
    console.log("getUserList");

    if ((DBCache.userList !== null ) && (force === false )) {
      console.log("returning cached value", DBCache.userList);
      callbackFn(DBCache.userList);
      return;
    }

    const request = {
      command: 'getUsers'
    };

    function handler(response){
      console.log(response);
      if (response.status >= 200 && response.status < 300){
        const userlist = JSON.parse(response.responseText);
        console.log(userlist);
        DBCache.userList = userlist;
        callbackFn(userlist);
      }
      else {
        callbackFn(null)
      }        
    }

    this.serverFetch(request, handler);
  }

  getJobStatus(pJobArn, callbackfn) {
    console.log("Trying to get status for ARN " + pJobArn);
    
    const xhr = new XMLHttpRequest();
    xhr.onload = doCallBack;
    xhr.open("POST",DBCache.HANDLER, true);
    const reqBody = {
      command : 'getJobStatus',
      userInfo : DBCache.userVals,
      jobInfo : {jobArn: pJobArn}
    };
    this.setHeaders(xhr);
    console.log(reqBody);
    
    xhr.send(JSON.stringify(reqBody));

    function doCallBack(){
      console.log("received response for getJobStatus");
      if (xhr.status >= 200 && xhr.status < 300) {
        console.log(JSON.parse(xhr.responseText));
        callbackfn(JSON.parse(xhr.responseText));
      }
      else {
        console.log("Unable to get job status");
        callbackfn(null);
      }
    }
  }

  getJobList(callbackFn, force=false){
    if (DBCache.jobList && !force) {
      callbackFn(DBCache.jobList);
    }
    else
    {
      const xhr = new XMLHttpRequest();
      function setJobData() {
        if (xhr.status >= 200 && xhr.status < 300) {
          DBCache.jobList = JSON.parse(xhr.responseText)
          callbackFn(DBCache.jobList);  
        }
        else {
          console.error("Request failed with status :", xhr.status);
          // Nothing more to do...
        }
      }

      xhr.onload = setJobData;
      xhr.open("POST",DBCache.HANDLER,true);
      const reqBody = {
        command  : 'getJobs',
        userInfo : DBCache.userVals
      };

      this.setHeaders(xhr);
      xhr.send(JSON.stringify(reqBody));
    }
  };

  makeFilename(jobID, folder, num, name){
    return(jobID + "/" + folder + "/" + String(num).padStart(3,'0') + "" + name);
  }

  getFilenameFromBucketname(fullfilename){
    const ss = fullfilename.split('/');
    return (ss[ss.length-1].slice(3,));

    // return(fullfilename.split('/')[fillfilename.split()])
  }

  async uploadToBucket(jobID, attachments, folder, handler) {
    // No need to worry about bucket name, as this is handled by 
    // the lambda

    const MAXSIZE = 10*1024*1024 ; // 10MB

    if (!attachments)
      return;
    
    var i=0;
    var response;
    var reqBody;
    var data;
    var b64;

    // Verify that the files are less than the maximum size
    // 10MB for now.
    for (i=0; i < attachments.length; i++)
    {
      if (attachments[i].size > MAXSIZE)
      {
        console.log("File too large");
        window.alert("File too large " + attachments[i].name);
        handler(false);
        return false;
      }
    }

    // verify folder param is present
    if (!folder) {
      console.log("Folder not present")
      window.alert("Error: Folder not provided for file upload");
      return;
    }
    
    function getFileAsBase64(file) {
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
              const base64String = reader.result.split(',')[1];
              resolve(base64String);
          };
          reader.onerror = (error) => {
              reject(error);
          };
          reader.readAsDataURL(file);
      });
    }

    // Now start posting them one by one...
    // May be a long operation
    
    for (i=0; i < attachments.length; i++)
    {
      try {
        b64 = await getFileAsBase64(attachments[i])
        reqBody = {
          command : 'putFileInBucket',
          userInfo : DBCache.userVals,
          filename: this.makeFilename(jobID, folder, i, attachments[i].name),
          filebody : b64
        };

        response = await fetch(DBCache.HANDLER, {
          method: "POST",
          body : JSON.stringify(reqBody)
        });

        data = await response.json();
        console.log(data);
      } catch (err)
      {
        console.log(err);
        window.alert(err);
        handler(false);
        return false;
      }
    }
    handler(true);
    return true;
  }

  async createNewJob(jobData, handler){
    console.log("Create new job");
    console.log(jobData);

    const attachmentList = [];
    for (let i=0; i < jobData.attachments.length; i++)
      attachmentList.push(jobData.jobID + '/A' + i + jobData.attachments[i].name);

    console.log(attachmentList);
    var response;

    const reqBody = {
      command: 'createJob',
      userInfo: DBCache.userVals,
      jobID : jobData.jobID,
      jobName : jobData.jobName,
      jobDescription: jobData.jobDescription,
      jobRequirements: jobData.jobRequirements,
      siteName: jobData.siteName,
      attachments: attachmentList,
      jobStatus : 'REQUESTED'
    };

    try {
      response = await fetch(DBCache.HANDLER, {
        method: 'POST',
        body: JSON.stringify(reqBody)
      });
      if (response.status === 200) {
        handler(true);
        return true;
      } else {
        console.log(response);
        window.alert("Job creation failed");
      }
    }catch (err)
    {
      console.log(err);
      window.alert(err);
      handler(false);
      return false;
    }
    handler(true);
    return true;
  }

  getJobDetails(pJobID, callbackFn) {

    const xhr= new XMLHttpRequest();
    function setJobData() {
      if (xhr.status >= 200 && xhr.status < 300) {
        // console.log(JSON.parse(xhr.response).response);

        const retval = JSON.parse(xhr.response).response;
        /* Stupid dynamodDB is gone !!
        const unmarshalled_retval = cv.DDBUnmarshall(retval); 
        callbackFn(unmarshalled_retval);
        */
        callbackFn(retval);
      }
      else{
        console.log("getJobDetails failed ", xhr);
        // TODO -- need an alarm here.
      }
    }
    xhr.onload = setJobData;
    xhr.open("POST",DBCache.HANDLER, true);
    const reqBody = {
      command: 'getJobDetails',
      userInfo: DBCache.userVals,
      jobID : pJobID,
    }
    this.setHeaders(xhr);
    xhr.send(JSON.stringify(reqBody));
  }

  getFileFromBucket(pFilename){
    const xhr = new XMLHttpRequest();
    const localfile = this.getFilenameFromBucketname(pFilename);

    function handleFile() {
      if (xhr.status >= 200 && xhr.status < 300)
      {
        // console.log("response received");
        // console.log(JSON.parse(xhr.response));
        const bytes = new Uint8Array(JSON.parse(xhr.response).response.data)
        const blob = new Blob([bytes]);
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = localfile;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } else {
        // console.log("File download failed");
        window.alert("File download failed");
        // TODO : Show a toast on failure
      }
    }
    xhr.onload = handleFile;
    xhr.open("POST", DBCache.HANDLER, true);
    const reqBody = {
      command: 'getFileFromBucket',
      userInfo: DBCache.userVals,
      filename : pFilename,
    }
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(reqBody));
  }

  setJobStatus(pJobID,pStatus){

    // TODO : Verify status needs to be validated.
    console.log("setJobStatus for " + pJobID + " to " + pStatus);
    

    const xhr = new XMLHttpRequest();
    function verifySetJobStatus() {
      if (xhr.status >= 200 && xhr.status < 300) {
        console.log(JSON.parse(xhr.response));
        // TODO : Show a toast on success
      } else {
        console.log("setJobStatus failed");
        // TODO : Show a failure toast
      }
    }

    xhr.onload = verifySetJobStatus;
    xhr.open("POST", DBCache.HANDLER, true);
    const reqBody = {
      command: 'setJobStatus',
      userInfo: DBCache.userVals,
      jobID : pJobID,
      jobStatus : pStatus,
    }
    console.log(reqBody);

    this.setHeaders(xhr);
    xhr.send(JSON.stringify(reqBody));
  }

  setJobStaging(pJobID, pStagingName, callback) {
    console.log("setJobStaging for " + pJobID + " to " + pStagingName);
    const request = {
      command:        'setJobStaging',
      jobID:          pJobID,
      stagingName:    pStagingName,
    };

    function responseHandler(response){
      console.log('got response');
      console.log(response);
      const retval = (response.status >= 200) &&
                     (response.status < 300) ?
                      {result:'success'}: 
                      {result:'fail'};
      callback(retval);
    }

    this.serverFetch(request, responseHandler);
    
  }

  AddNoteToJob(pJobID, pNote, callbackFn){
    // TODO : Validate that the user has the right permissions etc. 

    console.log("add note to job for job ID " + pJobID + " Note is " + JSON.stringify(pNote));

    const xhr= new XMLHttpRequest();
    function onResponse() {
      if (xhr.status >= 200 && xhr.status < 300) {
        console.log(JSON.parse(xhr.response));
        callbackFn(pJobID, pNote, true);
      } else {
        console.log("Add Note failed");
        console.log(xhr.response);
        callbackFn(pJobID,pNote,false);
      }
    }

    xhr.onload = onResponse;
    xhr.open("POST", DBCache.HANDLER, true);
    const reqBody = {
      command: 'addNoteToJob',
      userInfo: DBCache.userVals,
      jobID : pJobID,
      noteVal : pNote,
    }

    console.log(reqBody);

    this.setHeaders(xhr);
    xhr.send(JSON.stringify(reqBody));

  }

  syncContent(pJobID, pBranchName, callback )  {
    console.log("syncContent for " + pJobID + " branch name " + pBranchName );
    const xhr = new XMLHttpRequest();
    function syncDone() {
      if (xhr.status >= 200 && xhr.status < 300) {
        console.log(JSON.parse(xhr.response));
        callback(xhr.response);
      } else {
        console.log("Sync Failed ", xhr.response);
        callback(null);
      }      
    }

    xhr.onload = syncDone;
    xhr.open("POST", DBCache.HANDLER, true);
    const reqBody = {
      command: "syncContent",
      userInfo: DBCache.userVals,
      jobID: pJobID,
      branch : pBranchName,
    }
    console.log(reqBody);

    this.setHeaders(xhr);
    xhr.send(JSON.stringify(reqBody));
  }

  getDepartmentList(callbackFn, force=false) {
    console.log("get departments List");

    if ((DBCache.departmentsList !== null ) && (force === false )) {
      console.log("returning cached value", DBCache.departmentsList);
      callbackFn(DBCache.departmentsList);
      return;
    }

    const request = {
      command: 'getDepartments'
    };

    function handler(response){
      console.log(response);
      if (response.status >= 200 && response.status < 300){
        const departmentslist = JSON.parse(response.responseText);
        console.log(departmentslist);
        DBCache.departmentsList = departmentslist;
        callbackFn(departmentslist);
      }
      else {
        callbackFn(null)
      }
    }

    this.serverFetch(request, handler);
  }

  getRolesList(callbackFn, force=false) {
    console.log("get roles List");

    if ((DBCache.rolesList != null ) && (force === false )) {
      console.log("returning cached value", DBCache.rolesList);
      callbackFn(DBCache.rolesList);
      return;
    }

    const request = {
      command: 'getRoles'
    };

    function handler(response){
      console.log(response);
      if (response.status >= 200 && response.status < 300){
        const roleslist = JSON.parse(response.responseText);
        console.log(roleslist);
        DBCache.rolesList = roleslist;
        callbackFn(roleslist);
      }
      else {
        callbackFn(null)
      }        
    }

    this.serverFetch(request, handler);
  }

  addUser(userValsParam, callback) {
    console.log("Adding user ", userValsParam);

    // TODO - Add validations
    const request = {
      command:    'addUser',
      newUserInfo: userValsParam
    };

    function handler(response) {
      console.log(response);
      const retval = (response.status >= 200) && (response.status < 300) ? 
                      JSON.parse(response.responseText): 
                      false;
      callback(retval);
    }

    this.serverFetch(request, handler);
  }
  
  deployToProduction(pJobID, callback) {
    console.log("About to deploy jobID ", pJobID);
    const request = {
      command: 'deployToProduction',
      jobID :  pJobID
    };

    function handler(response) {
      console.log(response);
      const retval = (response.status >= 200) && (response.status < 300) ? 
                      JSON.parse(response.responseText): 
                      false;
      callback(retval);
    }
    this.serverFetch(request,handler);
  }
  
  getGitURL(pSiteName){
    console.log(DBCache.siteList);
    for (let i=0; DBCache.siteList && i < DBCache.siteList.length; i++){
      if (DBCache.siteList[i].siteName === pSiteName)
        return DBCache.siteList[i].gitURL;
    }
    console.error("Could not find the git URL for site ", pSiteName);
    return("https://github.com/JapanSriram/hajime_classic.git"); // The error case is hardcoded. TODO: Throw an error at this stage.
  }

  getLeadList(setLeadList, filter=null){
    // This always gets from server - no caching
    console.log("getting leads list");
    const request = {
      command: 'getLeadList',
      maxCount: 100,      
      filter: filter,
    };
    function handler(response){
      console.log(response);
      const retval = (response.status >= 200) && (response.status < 300) ? 
                      JSON.parse(response.responseText): 
                      false;
      setLeadList(retval?retval:null);
    }

    this.serverFetch(request,handler);
  }

  addNoteToLead(leadID, noteVals, callback){
    try{
      console.log("Adding note " , noteVals , "to lead" , leadID);
      const request = {
        command: 'addNoteToLead',
        leadID : leadID,
        note: noteVals
      };
      function handler(response){
        console.log(response);
        const retval = (response.status >= 200) && (response.status < 300) ? 
                      true: 
                      false;        
        callback(retval);
      }
      this.serverFetch(request, handler);
    } catch(err){
      console.log(err);
      callback(false);
    }
  }

  setLeadOwner(leadID, callback ){
    try{
      console.log("Setting owner for lead " + leadID + " to user " + DBCache.userVals.userName);
      function handler(response){
        console.log(response);
        const retval = (response.status >= 200) && (response.status < 300) ? 
                      true: 
                      false;        
        callback(retval);
      }
      const request = {
        command: 'setLeadStatus',
        leadID: leadID,
        leadStatus : 'ASSIGNED',
        leadOwner: DBCache.userVals.userName
      }
      this.serverFetch(request, handler);
    }
    catch(err){
      console.error(err);
      callback(false);
    }
  }

  setLeadStatus(pLeadID, status, callback){
    try{
      function handler(response){
        console.log(response);
        const retval = (response.status >= 200 ) && (response.status < 300) ? true : false;
        callback(retval);
      }
      const request = {
        command: 'setLeadStatus',
        leadID: pLeadID,
        leadStatus: status,        
      }
      this.serverFetch(request,handler);
    } catch(err){
      console.error(err);
      callback(false);
    }
  }

  getLeadFlowData(pLeadVals, callback){
    try{
      function handler(response){
        console.log(response);
        const retval = (response.status >= 200) && (response.status < 300) ? 
        JSON.parse(response.responseText): 
        null;
        callback(retval)
      }
      const request = {
        command: 'getLeadFlowData',
        filter : pLeadVals
      };
      this.serverFetch(request, handler);
    }
    catch(err){
      console.error(err);
      callback(null);
    }
  }

  async login(userID, password){

    if(password === 'hello') 
      return 'success';
    else
      return null;
  }

  async logout(){
  }

  async startSession(){
    // 3 way handshake...
    
  }

  async InitiConnection(){
        /***
         * Three way handshake with server.
         * App / server encryption and secure key exchange protocol
         *  The server side consists of a auth microservice implemented as a lambda in aws which handles
         *  Initialization of keys and cryptography.
         *  Session initiation with the app.
         *  Initiation at the server side : 
         * Lambda checks with aws key store/kms/hsm/tmp storage if there is an existing key pair
         * If key pair not present, keys are created (public and private)
         * Public key is retrieved into the lambda memory. Private key stays within aws kms/hsm or on lambda tmp storage equivalent
         * App initiation
         * On startup, app checks for device ID. if not present, unique device ID is created
         * App sends device id to server, request for public key
         * App creates a random session key, encrypts using public key
         * Device id  + encrypted session key sent to server
         * Server decrypts session key and stores it in database with session expiry, replies with session ID encrypted with the session key.
         *  Session key is used to encrypt payload on each api call. This will be a symmetric encryption algorithm
         *.
         * STATE DIAGRAM FOR THE CONNECTION INITIATION
         * 1. INITIAL
         * 2. AWAITPUBKEY
         * 3. AWAITSESSIONID
         * 4. FAILED
         * 5. SUCCESS
         * 
         * 
         */

        try {
            //console.log('about to fetch');
            const deviceID = this.getDeviceID();
            let request = {
                command: 'getPublicKey',
                requestorInfo: {
                    deviceID : deviceID,
                },
                requestParams: {},
            }
            let api = APIROOT + '/auth/publicKey';
            let payload = {
                headers: {'Content-type':'application/json'},
                body:JSON.stringify(request),
                method:'POST',
            };

            const pubkeyresp = await fetch(api,payload);
            // console.log(pubkeyresp);
            if(!pubkeyresp.ok)
                throw(new Error('Error fetching public key'));

            let json = await pubkeyresp.json();
            // console.log(json);
            const pem = JSON.parse(json.body).publicKey;
            //console.log(pem);
        
            // We have the public key, now create a random session key 
            // and encrypt it for comms
            const sessionKey = forge.random.getBytesSync(32);
            const initVector = forge.random.getBytesSync(32); 
            const keys       = {
                sessionKey : sessionKey,
                initVector : initVector,
            };
            //console.log('session key:' + forge.util.encode64(sessionKey));   //THIS MUST BE DELETED. ONLY FOR DEBUGGING
           
            // Encrypt the session key using the public key
            const publicKey             = forge.pki.publicKeyFromPem(pem);
            const encryptedSessionKey   = publicKey.encrypt(sessionKey);
            const encrSessKeyB64        = forge.util.encode64(encryptedSessionKey);
            //console.log('encr sess key with rsa pub key');
            //console.log(encrSessKeyB64);


            
            // For a cross check at the server, encrypt the public key
            // using the session key , using AES algorithm.
            // the server should decipher this and verify that the original
            // public key is available.

            const cipher = forge.cipher.createCipher('AES-CBC',sessionKey);
            cipher.start({iv:initVector});
            cipher.update(forge.util.createBuffer(pem));
            cipher.finish();
            const encrPubKey            = cipher.output.getBytes();
            //console.log('cipher done')
            // console.log(encrPubKey.toHex())
            const encrPubKeyB64         = forge.util.encode64(encrPubKey);


            //we need to send the init vector as well, so encrypt this using the 
            // public key as well
            const encrIV                = publicKey.encrypt(initVector);
            const encrIV64              = forge.util.encode64(encrIV);

            
            //console.log('aes pub key');
            //console.log(encrPubKeyB64);

            const decipher        = forge.cipher.createDecipher('AES-CBC',sessionKey);
            decipher.start({iv:initVector});
            decipher.update(forge.util.createBuffer(encrPubKey));
            const decResult       = decipher.finish();
            if(!decResult)
                throw new Error('Public key decryption failed');
            //else
                //console.log('got good decipher result');

            const derivedPubKey   = decipher.output;
            console.log('derived public key');
            console.log(derivedPubKey);

            request = {
                command: 'startSession',
                requestorInfo: {
                    deviceID: deviceID,
                },
                encryption  : false,
                plainPayload: {
                    SessionKey: encrSessKeyB64,
                    InitVector: encrIV64,
                    encrPubKey: encrPubKeyB64,
                    clientTime: Date.now(),
                },
                payloadHash : '', // TODO - populate this correctly .
            }

            payload = {
                headers: {'Content-type':'application/json'},
                body:JSON.stringify(request),
                method:'POST',
            };
            api = APIROOT + '/auth/startSession';
            const sessresp = await fetch(api,payload);
            //console.log(sessresp);
            if(!sessresp.ok){
                throw(new Error('session init failure'));
            }
            json = await sessresp.json();
            //console.log(json);
            // the payload is encrypted with the sessionkey, need to decrypt it
            const body = JSON.parse(decrypt(forge.util.decode64(json.body),keys));
            //console.log(body);

            const sessionID = body.sessionID;
            const sessionExpiry = body.expiresAt;

            // Now initialize the UserProfile object with all this info.
            this.TheProfile.Connected                    = true;
            this.TheProfile.Crypto.publicKey             = publicKey;
            this.TheProfile.Crypto.sessionKey            = sessionKey;
            this.TheProfile.Crypto.initVector            = initVector;
            this.TheProfile.Crypto.sessionID             = sessionID;
            this.TheProfile.Crypto.sessionExpiry         = sessionExpiry;
            this.TheProfile.DeviceInformation.deviceID   = deviceID;

            //console.log(JSON.stringify(UserProfile.TheProfile));


        } catch (err) {
            console.error(err);
            return false;
        }

        return true;
    }
  
  initVideo(nameval,handler){
    try{
      console.log('video call initiated');
      const request = {
        command : 'initVideo',
        participantID: nameval,
      };

      function responseHandler(response){
        console.log('got response');
        console.log(response);
        const retval = (response.status >= 200) && (response.status < 300) ? 
                      JSON.parse(response.responseText): 
                      null;
        handler(retval);
      }

      this.serverFetch(request, responseHandler);
    }
    catch(err){
      console.error(err);
      handler(null);
    }
  }

  getPhoneToken(handler){
    try{
      console.log('phone call token requested');

      if(DBCache.CallToken !== null) {
        handler(DBCache.CallToken);
        return;
      }

      const request = {
        command: 'getPhoneToken',
        participantID: 'suji.sriram',
      };

      function responseHandler(response){
        console.log('got phone token response');
        console.log(response);
        const retval = (response.status >= 200) && (response.status < 300) ?
          JSON.parse(response.responseText) :
          null;
        if(retval)
          DBCache.CallToken = retval;
        handler(retval);
      }

      this.serverFetch(request, responseHandler);

    } catch(err){
      console.error(err);
      handler(null);
    }
  }

  getProductivity(pLeadVals,callback){
    try{
      function handler(response){
        console.log(response);
        const retval = (response.status >= 200) && (response.status < 300) ? 
        JSON.parse(response.responseText): 
        null;
        callback(retval)
      }
      const request = {
        command: 'getProductivity',
        filter : pLeadVals
      };
      //window.setTimeout(()=>{callback(dummydata)}, 1000); //TODO:Only for testing without network
      this.serverFetch(request, handler);
    }
    catch(err){
      console.error(err);
      callback(null);
    }
  }
}// End of Object  


export default DBCache;


const dummydata=[
    {
        "_id": {
            "leadOwner": "Shreya Das",
            "disposition": "ASSIGNED"
        },
        "cumulativeTime": 1.5140432098765433,
        "number": 27,
        "duration": 1.514043209876543
    },
    {
        "_id": {
            "leadOwner": "Vihaan Singh",
            "disposition": "CONVERTED"
        },
        "cumulativeTime": 4.316319444444444,
        "number": 2,
        "duration": 0.9003472222222222
    },
    {
        "_id": {
            "leadOwner": "Sahil Mishra",
            "disposition": "CONTACTED"
        },
        "cumulativeTime": 2.627314814814815,
        "number": 3,
        "duration": 1.035648148148148
    },
    {
        "_id": {
            "leadOwner": "Vikram Choudhary",
            "disposition": "COLD"
        },
        "cumulativeTime": 5.054861111111111,
        "number": 1,
        "duration": 1.3243055555555556
    },
    {
        "_id": {
            "leadOwner": "Sunita Agarwal",
            "disposition": "ASSIGNED"
        },
        "cumulativeTime": 2.5238425925925925,
        "number": 3,
        "duration": 2.5238425925925925
    },
    {
        "_id": {
            "leadOwner": "Lata Mishra",
            "disposition": "ASSIGNED"
        },
        "cumulativeTime": 0.830324074074074,
        "number": 3,
        "duration": 0.830324074074074
    },
    {
        "_id": {
            "leadOwner": "Nishant Kulkarni",
            "disposition": "CONTACTED"
        },
        "cumulativeTime": 3.4663194444444443,
        "number": 2,
        "duration": 1.2798611111111111
    },
    {
        "_id": {
            "leadOwner": "Pooja Nair",
            "disposition": "COLD"
        },
        "cumulativeTime": 2.2307870370370373,
        "number": 3,
        "duration": 0.6835648148148148
    },
    {
        "_id": {
            "leadOwner": "Vihaan Singh",
            "disposition": "ASSIGNED"
        },
        "cumulativeTime": 0.9697222222222223,
        "number": 5,
        "duration": 0.9697222222222223
    },
    {
        "_id": {
            "leadOwner": "Pooja Nair",
            "disposition": "WARM"
        },
        "cumulativeTime": 5.205853174603175,
        "number": 7,
        "duration": 1.764484126984127
    },
    {
        "_id": {
            "leadOwner": "Pooja Nair",
            "disposition": "CONVERTED"
        },
        "cumulativeTime": 6.194907407407407,
        "number": 6,
        "duration": 0.8893518518518518
    },
    {
        "_id": {
            "leadOwner": "Varun Agarwal",
            "disposition": "ASSIGNED"
        },
        "cumulativeTime": 1.6439814814814815,
        "number": 3,
        "duration": 1.6439814814814815
    },
    {
        "_id": {
            "leadOwner": "Divya Iyer",
            "disposition": "LOST"
        },
        "cumulativeTime": 6.361111111111111,
        "number": 1,
        "duration": 1.0229166666666667
    },
    {
        "_id": {
            "leadOwner": "Sahil Mishra",
            "disposition": "WARM"
        },
        "cumulativeTime": 3.5930555555555554,
        "number": 1,
        "duration": 2.432638888888889
    },
    {
        "_id": {
            "leadOwner": "Sunita Agarwal",
            "disposition": "LOST"
        },
        "cumulativeTime": 7.013888888888889,
        "number": 1,
        "duration": 0.7701388888888889
    },
    {
        "_id": {
            "leadOwner": "Varun Agarwal",
            "disposition": "COLD"
        },
        "cumulativeTime": 5.238194444444445,
        "number": 1,
        "duration": 2.126388888888889
    },
    {
        "_id": {
            "leadOwner": "Priya Kapoor",
            "disposition": "CONTACTED"
        },
        "cumulativeTime": 2.8756944444444446,
        "number": 3,
        "duration": 1.699537037037037
    },
    {
        "_id": {
            "leadOwner": "Sahil Mishra",
            "disposition": "COLD"
        },
        "cumulativeTime": 3.5555555555555554,
        "number": 1,
        "duration": 0.9715277777777778
    },
    {
        "_id": {
            "leadOwner": "Aditi Choudhary",
            "disposition": "NOTCONTACTABLE"
        },
        "cumulativeTime": 2.552916666666667,
        "number": 5,
        "duration": 1.3073611111111112
    },
    {
        "_id": {
            "leadOwner": "Sneha Gupta",
            "disposition": "ASSIGNED"
        },
        "cumulativeTime": 0.17569444444444443,
        "number": 2,
        "duration": 0.17569444444444443
    },
    {
        "_id": {
            "leadOwner": "Aditi Choudhary",
            "disposition": "LOST"
        },
        "cumulativeTime": 9.861805555555556,
        "number": 1,
        "duration": 2.3375
    },
    {
        "_id": {
            "leadOwner": "Lata Mishra",
            "disposition": "CONTACTED"
        },
        "cumulativeTime": 2.109722222222222,
        "number": 3,
        "duration": 1.2793981481481482
    },
    {
        "_id": {
            "leadOwner": "Shreya Das",
            "disposition": "NOTCONTACTABLE"
        },
        "cumulativeTime": 3.0538194444444446,
        "number": 2,
        "duration": 1.5756944444444445
    },
    {
        "_id": {
            "leadOwner": "Divya Iyer",
            "disposition": "WARM"
        },
        "cumulativeTime": 4.8050347222222225,
        "number": 12,
        "duration": 1.5972222222222223
    },
    {
        "_id": {
            "leadOwner": "Sneha Gupta",
            "disposition": "COLD"
        },
        "cumulativeTime": 4.20625,
        "number": 1,
        "duration": 0.9895833333333334
    },
    {
        "_id": {
            "leadOwner": "Aditi Choudhary",
            "disposition": "CONTACTED"
        },
        "cumulativeTime": 3.426455026455026,
        "number": 21,
        "duration": 1.958366402116402
    },
    {
        "_id": {
            "leadOwner": "Sunita Agarwal",
            "disposition": "WARM"
        },
        "cumulativeTime": 4.7555555555555555,
        "number": 1,
        "duration": 1.1493055555555556
    },
    {
        "_id": {
            "leadOwner": "Nishant Kulkarni",
            "disposition": "ASSIGNED"
        },
        "cumulativeTime": 2.035185185185185,
        "number": 3,
        "duration": 2.035185185185185
    },
    {
        "_id": {
            "leadOwner": "Pooja Nair",
            "disposition": "LOST"
        },
        "cumulativeTime": 4.735763888888889,
        "number": 2,
        "duration": 2.646875
    },
    {
        "_id": {
            "leadOwner": "suji.sriram@gmail.com",
            "disposition": "ASSIGNED"
        },
        "cumulativeTime": 2.4256944444444444,
        "number": 1,
        "duration": 2.4256944444444444
    },
    {
        "_id": {
            "leadOwner": "Divya Iyer",
            "disposition": "COLD"
        },
        "cumulativeTime": 5.063425925925926,
        "number": 3,
        "duration": 1.8453703703703703
    },
    {
        "_id": {
            "leadOwner": "Sneha Gupta",
            "disposition": "WARM"
        },
        "cumulativeTime": 4.095138888888889,
        "number": 1,
        "duration": 1.1388888888888888
    },
    {
        "_id": {
            "leadOwner": "Varun Agarwal",
            "disposition": "NOTCONTACTABLE"
        },
        "cumulativeTime": 3.7597222222222224,
        "number": 1,
        "duration": 2.6020833333333333
    },
    {
        "_id": {
            "leadOwner": "Aditi Choudhary",
            "disposition": "WARM"
        },
        "cumulativeTime": 4.705208333333333,
        "number": 10,
        "duration": 1.556875
    },
    {
        "_id": {
            "leadOwner": "Divya Iyer",
            "disposition": "NOTCONTACTABLE"
        },
        "cumulativeTime": 2.536111111111111,
        "number": 3,
        "duration": 1.6465277777777778
    },
    {
        "_id": {
            "leadOwner": "Sunita Agarwal",
            "disposition": "COLD"
        },
        "cumulativeTime": 6.24375,
        "number": 1,
        "duration": 2.045138888888889
    },
    {
        "_id": {
            "leadOwner": "Swati Joshi",
            "disposition": "NOTCONTACTABLE"
        },
        "cumulativeTime": 4.557638888888889,
        "number": 1,
        "duration": 1.675
    },
    {
        "_id": {
            "leadOwner": "Swati Joshi",
            "disposition": "COLD"
        },
        "cumulativeTime": 3.3631944444444444,
        "number": 1,
        "duration": 0.7479166666666667
    },
    {
        "_id": {
            "leadOwner": "Nishant Kulkarni",
            "disposition": "COLD"
        },
        "cumulativeTime": 4.829166666666667,
        "number": 1,
        "duration": 2.082638888888889
    },
    {
        "_id": {
            "leadOwner": "Varun Agarwal",
            "disposition": "LOST"
        },
        "cumulativeTime": 6.897222222222222,
        "number": 1,
        "duration": 1.6590277777777778
    },
    {
        "_id": {
            "leadOwner": "Divya Iyer",
            "disposition": "CONTACTED"
        },
        "cumulativeTime": 3.11937134502924,
        "number": 19,
        "duration": 1.5869883040935673
    },
    {
        "_id": {
            "leadOwner": "Shreya Das",
            "disposition": "WARM"
        },
        "cumulativeTime": 4.052546296296296,
        "number": 6,
        "duration": 1.2581018518518519
    },
    {
        "_id": {
            "leadOwner": "Swati Joshi",
            "disposition": "CONTACTED"
        },
        "cumulativeTime": 2.941435185185185,
        "number": 3,
        "duration": 1.7127314814814816
    },
    {
        "_id": {
            "leadOwner": "Priya Kapoor",
            "disposition": "WARM"
        },
        "cumulativeTime": 4.009722222222222,
        "number": 1,
        "duration": 1.5631944444444446
    },
    {
        "_id": {
            "leadOwner": "Vihaan Singh",
            "disposition": "NOTCONTACTABLE"
        },
        "cumulativeTime": 2.5753472222222222,
        "number": 2,
        "duration": 0.9819444444444444
    },
    {
        "_id": {
            "leadOwner": "Varun Agarwal",
            "disposition": "CONTACTED"
        },
        "cumulativeTime": 3.1118055555555557,
        "number": 1,
        "duration": 0.5145833333333333
    },
    {
        "_id": {
            "leadOwner": "Vikram Choudhary",
            "disposition": "LOST"
        },
        "cumulativeTime": 5.775,
        "number": 1,
        "duration": 0.7201388888888889
    },
    {
        "_id": {
            "leadOwner": "Aditi Choudhary",
            "disposition": "ASSIGNED"
        },
        "cumulativeTime": 1.3426157407407406,
        "number": 30,
        "duration": 1.3426157407407406
    },
    {
        "_id": {
            "leadOwner": "Divya Iyer",
            "disposition": "CONVERTED"
        },
        "cumulativeTime": 5.893634259259259,
        "number": 6,
        "duration": 1.3769675925925926
    },
    {
        "_id": {
            "leadOwner": "Aditi Choudhary",
            "disposition": "CONVERTED"
        },
        "cumulativeTime": 5.253472222222222,
        "number": 5,
        "duration": 0.8734722222222222
    },
    {
        "_id": {
            "leadOwner": "Swati Joshi",
            "disposition": "WARM"
        },
        "cumulativeTime": 3.3649305555555555,
        "number": 2,
        "duration": 0.2604166666666667
    },
    {
        "_id": {
            "leadOwner": "Sunita Agarwal",
            "disposition": "CONTACTED"
        },
        "cumulativeTime": 3.9024305555555556,
        "number": 2,
        "duration": 1.2121527777777779
    },
    {
        "_id": {
            "leadOwner": "Shreya Das",
            "disposition": "CONTACTED"
        },
        "cumulativeTime": 3.3190789473684212,
        "number": 19,
        "duration": 1.8227704678362573
    },
    {
        "_id": {
            "leadOwner": "Vikram Choudhary",
            "disposition": "CONTACTED"
        },
        "cumulativeTime": 3.7305555555555556,
        "number": 1,
        "duration": 2.685416666666667
    },
    {
        "_id": {
            "leadOwner": "Swati Joshi",
            "disposition": "ASSIGNED"
        },
        "cumulativeTime": 1.6421875,
        "number": 4,
        "duration": 1.6421875
    },
    {
        "_id": {
            "leadOwner": "Riya Mehta",
            "disposition": "CONTACTED"
        },
        "cumulativeTime": 2.3618055555555557,
        "number": 1,
        "duration": 0.21388888888888888
    },
    {
        "_id": {
            "leadOwner": "Shreya Das",
            "disposition": "CONVERTED"
        },
        "cumulativeTime": 4.346701388888889,
        "number": 4,
        "duration": 1.2836805555555555
    },
    {
        "_id": {
            "leadOwner": "Vihaan Singh",
            "disposition": "WARM"
        },
        "cumulativeTime": 3.265740740740741,
        "number": 3,
        "duration": 1.489814814814815
    },
    {
        "_id": {
            "leadOwner": "Nishant Kulkarni",
            "disposition": "LOST"
        },
        "cumulativeTime": 5.492361111111111,
        "number": 1,
        "duration": 0.6631944444444444
    },
    {
        "_id": {
            "leadOwner": "Sneha Gupta",
            "disposition": "CONTACTED"
        },
        "cumulativeTime": 3.0864583333333333,
        "number": 2,
        "duration": 2.910763888888889
    },
    {
        "_id": {
            "leadOwner": "Lata Mishra",
            "disposition": "WARM"
        },
        "cumulativeTime": 3.8041666666666667,
        "number": 1,
        "duration": 1.3381944444444445
    },
    {
        "_id": {
            "leadOwner": "Aditi Choudhary",
            "disposition": "COLD"
        },
        "cumulativeTime": 5.734288194444444,
        "number": 8,
        "duration": 1.8271701388888888
    },
    {
        "_id": {
            "leadOwner": "Pooja Nair",
            "disposition": "ASSIGNED"
        },
        "cumulativeTime": 1.5808641975308642,
        "number": 18,
        "duration": 1.5808641975308642
    },
    {
        "_id": {
            "leadOwner": "Pooja Nair",
            "disposition": "CONTACTED"
        },
        "cumulativeTime": 2.834085648148148,
        "number": 12,
        "duration": 1.3943865740740742
    },
    {
        "_id": {
            "leadOwner": "Sunita Agarwal",
            "disposition": "CONVERTED"
        },
        "cumulativeTime": 5.954166666666667,
        "number": 1,
        "duration": 1.198611111111111
    },
    {
        "_id": {
            "leadOwner": "Riya Mehta",
            "disposition": "ASSIGNED"
        },
        "cumulativeTime": 2.1479166666666667,
        "number": 1,
        "duration": 2.1479166666666667
    },
    {
        "_id": {
            "leadOwner": "Sahil Mishra",
            "disposition": "ASSIGNED"
        },
        "cumulativeTime": 1.5916666666666668,
        "number": 3,
        "duration": 1.5916666666666666
    },
    {
        "_id": {
            "leadOwner": "Vihaan Singh",
            "disposition": "CONTACTED"
        },
        "cumulativeTime": 1.775925925925926,
        "number": 3,
        "duration": 1.2219907407407407
    },
    {
        "_id": {
            "leadOwner": "Divya Iyer",
            "disposition": "ASSIGNED"
        },
        "cumulativeTime": 1.414641203703704,
        "number": 24,
        "duration": 1.4146412037037037
    },
    {
        "_id": {
            "leadOwner": "Vijay Das",
            "disposition": "ASSIGNED"
        },
        "cumulativeTime": 2.1958333333333333,
        "number": 2,
        "duration": 2.1958333333333333
    },
    {
        "_id": {
            "leadOwner": "Vikram Choudhary",
            "disposition": "ASSIGNED"
        },
        "cumulativeTime": 1.0451388888888888,
        "number": 1,
        "duration": 1.0451388888888888
    },
    {
        "_id": {
            "leadOwner": "Lata Mishra",
            "disposition": "CONVERTED"
        },
        "cumulativeTime": 6.448611111111111,
        "number": 1,
        "duration": 2.6444444444444444
    },
    {
        "_id": {
            "leadOwner": "Shreya Das",
            "disposition": "LOST"
        },
        "cumulativeTime": 6.985416666666667,
        "number": 2,
        "duration": 0.8340277777777778
    },
    {
        "_id": {
            "leadOwner": "Priya Kapoor",
            "disposition": "ASSIGNED"
        },
        "cumulativeTime": 1.1761574074074075,
        "number": 3,
        "duration": 1.1761574074074075
    },
    {
        "_id": {
            "leadOwner": "Priya Kapoor",
            "disposition": "COLD"
        },
        "cumulativeTime": 3.5461805555555554,
        "number": 2,
        "duration": 0.45590277777777777
    },
    {
        "_id": {
            "leadOwner": "Priya Kapoor",
            "disposition": "CONVERTED"
        },
        "cumulativeTime": 5.561111111111111,
        "number": 1,
        "duration": 1.551388888888889
    },
    {
        "_id": {
            "leadOwner": "Pooja Nair",
            "disposition": "NOTCONTACTABLE"
        },
        "cumulativeTime": 3.0057870370370368,
        "number": 3,
        "duration": 1.3206018518518519
    },
    {
        "_id": {
            "leadOwner": "Nishant Kulkarni",
            "disposition": "WARM"
        },
        "cumulativeTime": 6.513888888888889,
        "number": 1,
        "duration": 2.327777777777778
    },
    {
        "_id": {
            "leadOwner": "Shreya Das",
            "disposition": "COLD"
        },
        "cumulativeTime": 5.2171006944444445,
        "number": 8,
        "duration": 1.81328125
    },
    {
        "_id": {
            "leadOwner": "Nishant Kulkarni",
            "disposition": "CONVERTED"
        },
        "cumulativeTime": 9.258333333333333,
        "number": 1,
        "duration": 2.7444444444444445
    }
];

