import './css/HeaderBar.css';
import LoginManager from './LoginManager';

export default function HeaderBar(){
    return(
        <div className='HeaderBar'>
            <div className='LogoHolder'>
                <img alt='logo' src='logo.png' />
            </div>
            <div className="Banner">
                <p> Hajime (はじめ) - Advanced banking content management platform</p>
            </div>
            <div className="LoginHolder">
                <LoginManager />
            </div>
        </div>
    )
}
