import './css/BubbleChart.css';
import React, { useEffect, useRef } from 'react';

// TODO: move this into a common utility component.
function saturate(colorString){
  const hslMatch = colorString.match(/hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/);
  
  if(hslMatch){
    const hue = hslMatch[1];
    const lightness = hslMatch[3];
    return (`hsl(${hue}, 100%, 40%`);
  }

  return colorString;
}

function findMin(objArr, attr){
  let min = Number.MAX_VALUE;
  objArr.map((o) => {
    if(o[attr] && o[attr] < min)
      min = o[attr];
  });
  return min;
}

function findMax(objArr, attr){
  
  let max = Number.MIN_VALUE;
  objArr.map((o) => {
    if(o[attr] && o[attr] > max)
      max = o[attr];
  });
  return max;
}

function linearScale(x, minx,miny, maxx,maxy){
  const m = (maxy - miny) / (maxx - minx);
  const c = miny - (m * minx);
  return (m * x) + c;
}


export default function BubbleChart({data, width, height, yLabel, xLabel, onActive }){
  const yOffset         = 30;
  const xOffset         = 50;
  const rightOffset     = 50;
  const topOffset       = 50;
  const biggestBubble   = 0.10 // We want the largest bubble to be 10% of the width in radius 
  const smallestBubble  = 0.02 // We want the largest bubble to be 10% of the width in radius 
  const axisPad         = 0.1;
  const ticklength      = 4;

  
  const bubbles = []; // The incoming data will be converted to this.

  // Find the max and min x , y & z values
  const xmin = findMin(data,'x');
  const ymin = findMin(data,'y');
  const sizemin = findMin(data,'size');
  const xmax = findMax(data,'x');
  const ymax = findMax(data,'y');
  const sizemax = findMax(data,'size');

  if (xmax === xmin)
    xmax = xmin + 10;

  if (ymax === ymin)
    ymax = ymin + 10;

  //console.log(data);
  //console.log(xmin,ymin,sizemin,xmax,ymax,sizemax);


  // Now compute the individual bubbles
  //
  data.map((b) => {
    const bubble = {
      cx : linearScale(b.x, xmin , xOffset, xmax, (width - rightOffset)), 
      cy : linearScale(b.y , ymin, height - yOffset, ymax,  topOffset),
      r  : linearScale(b.size, sizemin, width * smallestBubble, sizemax, width * biggestBubble),
      fill: b.color,
      label: b.label,
    }
    bubbles.push(bubble);
  });
  //console.log(bubbles);

  // Figure out the axis dimensions
  const yAxisMin = roundDown(ymin);
  const yAxisMax = roundUp(ymax);
  const xAxisMin = roundDown(xmin);
  const xAxisMax = roundUp(xmax);
  const xIncr    = Math.pow(10,Math.floor(Math.log10(xAxisMin)));
  const yIncr    = Math.pow(10,Math.floor(Math.log10(yAxisMin)));
  const xTicks   = [];
  const yTicks   = [];

  for(let i=xAxisMin; i <= xAxisMax; i += xIncr)
    xTicks.push({label: i.toFixed(1), val: linearScale(i, xmin, xOffset, xmax, (width - rightOffset))});
  for(let i=yAxisMin; i <= yAxisMax; i += yIncr)
    yTicks.push({label: i.toFixed(1), val: linearScale(i, ymin, height - yOffset, ymax, topOffset)});

  //console.log(`y axis from ${yAxisMin} to ${yAxisMax}. x axis from ${xAxisMin} to ${xAxisMax}.Incr are ${xIncr},${yIncr}`);
  //console.log(xTicks, yTicks);


  function roundDown(num){
    if (num < 1)
      return roundDown(num * 1000) / 1000;
    if(num < 10)
      return roundDown(num * 100)/100;
    else if (num < 100)
      return roundDown(num * 10)/10;
    else if (num >= 100 && num < 1000)
      return(Math.floor(num / 100) * 100);
    else if (num >= 1000 && num < 10000)
      return(Math.floor(num / 1000) * 1000);
    else
      return num;
  }
 
  function roundUp(num){
    if (num < 1)
      return roundUp(num * 1000) / 1000;
    if(num < 10)
      return 10;
    else if (num < 100)
      return 100;
    else if (num >= 100 && num < 1000)
      return(Math.ceil(num / 100) * 100);
    else if (num >= 1000 && num < 10000)
      return(Math.ceil(num / 1000) * 1000);
    else
      return num;
  }

  function handleMouseEnter(e,index){
    console.log(e);
    console.log(index);
    onActive(index);
  }

  function handleMouseLeave(){
    console.log('mouse leave');
    onActive(-1);
  }


  return(
    <>
      <svg version="1.1"
        width={width} height={height}
        xmlns="http://www.w3.org/2000/svg">
        {
          bubbles.map((b,i) => {
            return(
              <g key={i}>
                <circle 
                  onMouseEnter={(e)=>{handleMouseEnter(e,i)}} 
                  onMouseLeave={handleMouseLeave} 
                  key={i} 
                  cx={b.cx} 
                  cy={b.cy} 
                  r={b.r} 
                  fill={b.fill} 
                />
                <text 
                  x={b.cx} 
                  y={b.cy} 
                  fontSize="10">
                   {b.label}
                </text>
              </g>

            )
          })
        }
        {/*** Draw the axis lines ***/}
        <line x1={xOffset} y1={height - yOffset} x2={width-xOffset} y2={height - yOffset} stroke="black" strokeWidth="2"/> 
        {/**** Draw the Y axis line **/}
        <line x1={xOffset} y1={height - yOffset} x2={xOffset} y2={height - (height - yOffset)} stroke="black" strokeWidth="2"/>
        {/*** X Label ***/}
        <text x={width/2} y={height-yOffset+5} fontSize="12" alignmentBaseline='hanging'>{xLabel}</text>
        {/** Y Label **/}
        <text 
          x={xOffset-10} 
          y={(height-yOffset)/2} 
          fontSize="12" 
          transform={`rotate(270, ${xOffset-10},${(height-yOffset)/2})`}
        >
           {yLabel}
        </text>

        {/*** Draw x ticks and labels ***/}
        {
          xTicks.map((t,i) => { 
            return(
              <g key={i}>
                <line x1={t.val} y1={height - yOffset} x2={t.val} y2={height - yOffset + ticklength} stroke="black" strokeWidth="3" />
                <text x={t.val} y={height - yOffset + ticklength} fontSize="10" alignmentBaseline="hanging">{t.label}</text>
              </g>
            )
          })
        }

        {/*** draw y ticks and labels ***/}
        {
          yTicks.map((t,i) => {
            return(
              <g key={i}>
                <line x1={xOffset} y1={t.val} x2={xOffset - ticklength} y2={t.val} stroke='black' strokeWidth="3" />
                <text x={xOffset - ticklength - 20} y={t.val} fontSize='10' alignmentBaseline='middle'>{t.label}</text>
              </g>
            )
          })
        }
      </svg>
    </>
  )
}
