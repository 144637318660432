import './css/TabbedEdit.css'

import { useState } from 'react';


export default function TabbedEdit({title,tabs,layout}){
  const [currentTab, setCurrentTab] = useState(Object.keys(tabs)[0]);  // The index of the selected tab

  return(
    <div className='tabedit-holder'>
      <div className='tabedit-header'>
        <div className='tabedit-title'>
          <p> {title} </p>
        </div>
        <div className='tabedit-tabs-holder'>
          {
            Object.keys(tabs).map((t,n) => {
              return(
                <div 
                  className={t===currentTab?'tabedit-tab-title active':'tabedit-tab-title'} 
                  key = {n}
                  onClick={()=>setCurrentTab(t)}
                >
                  <p> {t} </p>
                </div>
              )})
          }
        </div>
      </div>

      <div className='tabedit-body'>
        {layout(currentTab)}
      </div>

    </div>
  );
}
