import './css/Sidebar.css';

export default function Sidebar({menulist,callbackFn}){
    var i = 0;
    return(
        <div className='Sidebar'>
            {menulist.map((m) => {return (
                <MenulistItem item = {m} callbackFn={callbackFn} key={i++}/>
            )})}
        </div>
    );

    function MenulistItem({item, callbackFn})
    {
        switch(item.type) {
            case 'head':
                return (
                    <div className='Menudivision'>
                        <p> {item.description} </p>
                    </div>
                );
            case 'link':
                return (
                    <div className='Menulink' onClick={()=>{callbackFn(item.menuItem)}}>
                        <p> {item.description} </p>
                    </div>
                )
            default:
                // Do nothing... TODO - Its a configuration error
        }
    }
}