import './css/FileLink.css'

import  DBCache  from './DBCache'; 


export default function FileLink({name}){
  const db = new DBCache();

  function ext(f){

    if(f && f.length > 0 ) {
      const ee= f.split('.').pop().toLowerCase();
      return ee;
    }
    else {
      return 'unk'
    }
  }

  const displayname=db.getFilenameFromBucketname(name);
  let   iconsrc ;
  switch(ext(name)){
    case 'doc':
    case 'docx':
      iconsrc = 'word_icon.png';
      break;
    case 'xls':
    case 'xlsx': 
      iconsrc = 'excel_icon.svg';
      break;
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'svg':
      iconsrc = 'pic_icon.svg';
      break;
    case 'pdf':
    case 'txt':
      iconsrc = 'doc_icon.svg';
      break;
    default :
      iconsrc = 'unknown.png';
  };

  return(
    <div className='file-icon-holder'>
      <img 
        className='file-icon-image'
        src = {iconsrc}
        onClick = {()=>db.getFileFromBucket(name)}
        alt = {displayname}
      />
      <p className='file-icon-name'>
        {displayname.slice(0,10)}
      </p>
    </div>
  )
}

