import './css/LeadAnalytics.css'

import { useEffect, useState } from 'react';

import HyozanForm from './HyozanForm';
import DBCache from './DBCache';
import CommonValidations from './Validations';
import Waiter from './Waiter';
import SankeyChart from './SankeyChart';
import BarChart from './BarChart';

const leadStatusVals = [
  'INITIAL',
  'ASSIGNED',
  'CONTACTED',
  'WARM',
  'CONVERTED',
  'LOST',
  'COLD',
  'NOTCONTACTABLE',
];

export default function LeadsFlow(){

  const [leadFlowData, setLeadFlowData] = useState([]);
  const [waiting, setWaiting]       = useState(false);

  const db = new DBCache();
  const cv = new CommonValidations();

  const formData = {
    startDate: '01/01/2023',
    endDate: '10/10/2023',
    formName: 'hyozan-form-hero'
  };

  const vals={
    FormName: 'Choose Leads Filters',
    Fields:[
      {
        Label: 'Start Date',
        Explanation: 'Choose the start date for lead data ',
        InputType: 'DATE',
        onChange: handleChange,
        Prompt:{
          Type: 'INFO',
          Label: 'Please enter start date for lead data '
        },
        Value: FormData.startDate,
        ID: 'startDate',
        InitValue : '2024-01-10'    // TODO - Compute this to beginning of this month
      },
      {
        Label: 'End Date',
        Explanation: 'Enter the end date for lead data',
        InputType: 'DATE',
        onChange: handleChange,
        Prompt: {
          Type: 'INFO',
          Label: 'Please enter end date for lead data...'
        },
        Value: FormData.endDate,
        ID: 'endDate',
        InitValue: '2024-01-11'     // TODO - Compute this to be yesterday
      },
      {
        Label: 'Forms',
        Explanation: 'Choose which forms you want lead info',
        InputType: 'LIST',
        Options: [
          {
            ID: 'hyozan-form-hero',
            Text: 'home page hero form'
          },
          {
            ID: 'hyozan-form-cards',
            Text: 'cards lead form'
          }
        ],
        onChange: handleChange,
        Prompt: {
          Type: 'INFO',
          Label: 'Choose forms'
        },
        ID: 'formName',
        InitValue: null
      }
    ],
    onSubmit: handleSubmit,
    onCancel: handleCancel,
    SubmitText: 'View Lead Analytics'
  };

  function handleChange(e){}

  function handleSubmit(vals){
    //console.log(vals);

    var lStartDate = cv.yyyymmdd2Date(vals.startDate);
    var lEndDate   = cv.yyyymmdd2Date(vals.endDate);
    
    /*** only for quick debug **/
    if(lStartDate === null) 
      lStartDate = cv.ddmmyyyy2Date('01/01/2023');
    if(lEndDate === null)
      lEndDate = cv.ddmmyyyy2Date('01/01/2025');
    /*** end debug **/

    if ((lStartDate  != null) &&  (lEndDate != null)) {
      const fieldvals = {
        startDate: lStartDate,
        endDate  : lEndDate,
        formName : vals.formName
      }
      setWaiting(true);
      db.getLeadFlowData(fieldvals, callback);
    }
  }

  function callback(data){
    setWaiting(false);
    setLeadFlowData(data);
  }

  function handleCancel(){
    setLeadFlowData([]);
  }

  return(
    <div className='hyozan-workspace'>
      {waiting && <Waiter />}
      <HyozanForm FormVals={vals} />
      <LeadFlowView leadData={leadFlowData}/>
    </div>
  )

  function LeadFlowTable({leadData}){
    return(
      <div className='hyozan-dashboard'>
        <div className='dashboard-header'>
          <div className='dashboard-title'>
            <p> Lead Flow Analytics </p>
          </div>
        </div>

        <div className='dashboard-spacer'></div>
        <table className='hyozan-table'>
          <LeadFlowViewHeader /> 
          <tbody>
            {leadData.map((lead) => {return(
               <LeadFlowRow rowVal={lead} />
            )})
            }
            <LeadFlowSummary leadData={leadData}/>
          </tbody>
        </table>
      </div>
    )
  }

  function LeadFlowSankey({leadData}){
    const [totals, grandTotal] = makeTotals(leadData);
    const t = totals; // just an alias
    const sankeyData=[
      { 
        from:   'Leads',      
        to:     'Assigned',   
        value:  t.ASSIGNED + t.COLD + t.CONTACTED + t.CONVERTED + t.LOST + t.NOTCONTACTABLE + t.WARM, 
        color: 'hsl(186, 40%, 60%)',
      },
      { 
        from:   'Leads',      
        to:     'Waiting',    
        value:  t.INITIAL,
        color: 'hsl(28, 40%, 60%)',
      },
      { 
        from:   'Assigned',   
        to:     'Contacted',  
        value:  t.COLD + t.CONTACTED + t.CONVERTED + t.LOST + t.WARM, 
        color: 'hsl(79, 40%, 60%)',
      },
      { 
        from:   'Contacted',  
        to:     'Converted',  
        value:  t.CONVERTED , 
        color: 'hsl(129, 40%, 60%)',
      },
      { 
        from:   'Contacted',  
        to:     'Warm',       
        value:  t.WARM + t.CONTACTED, 
        color: 'hsl(43, 40%, 60%)',
      },
      {
        from:   'Contacted',  
        to:     'Cold',       
        value:  t.COLD,
        color: 'hsl(186, 40%, 60%)',
      },
      { 
        from:   'Contacted',  
        to:     'Lost',       
        value:  t.LOST , 
        color: 'hsl(0, 40%, 60%)',
      },
      { 
        from:   'Assigned',   
        to:     'yet to contact', 
        value:  t.ASSIGNED ,
        color: 'hsl(27, 40%, 60%)',
      },
      { 
        from:   'Assigned',   
        to:     'uncontactable', 
        value:  t.NOTCONTACTABLE, 
        color: 'hsl(0, 40%, 60%)',
      },
    ];

    
    return(
      <>
        <SankeyChart data={sankeyData} width={800} height={1000} />
      </>
    )
  }

  function LeadFlowTrend({leadData}){
    const types={
      "INITIAL"         :{ color: 'hsl(0, 0%, 50%)',    legend:"Initial", },
      "ASSIGNED"        :{ color: 'hsl(28, 90%, 60%)',  legend:"Assigned",},
      "NOTCONTACTABLE"  :{ color: 'hsl(79, 80%, 60%)',  legend:"Not Contactable",},
      "WARM"            :{ color: 'hsl(129, 80%, 60%)', legend:"Warm",},
      "COLD"            :{ color: 'hsl(43, 80%, 60%)',  legend:"Cold",},
      "CONVERTED"       :{ color: 'hsl(186, 80%, 60%)', legend:"Converted",},
      "LOST"            :{ color: 'hsl(0, 80%, 60%)',   legend:"Lost",},
      "CONTACTED"       :{ color: 'hsl(27, 40%, 60%)',  legend:"Contacted",},
    };
    const bardata=[];

    leadData.map((d)=>{
      const vals=[];
      d.statusCounts.map((s)=>{
        vals.push({
          stackName: s.status,
          value: s.count,
        });
      });
      bardata.push({
        vals: vals,
        xLabel: d.date,
      });
    });
    
    return(
      <BarChart width={1000} height={800} data={bardata} stackNames={types} />
    )
  }

  function ViewButtons({handler}){
    return(
      <div className='lead-buttons-holder'>
        <div className='hyozan-form-button-area lead-buttons-holder'>
          <div onClick={()=>{handler('SANKEY')}} className='hyozan-form-button-holder'>
            <button className='hyozan-form-submit-button hyozan-button active'> Flow </button>
          </div>

          <div onClick={()=>{handler('TABLE')}} className='hyozan-form-button-holder'>
            <button className='hyozan-form-submit-button hyozan-button active'> Table </button>
          </div>

          
          <div onClick={()=>{handler('BARCHART')}} className='hyozan-form-button-holder'>
            <button className='hyozan-form-submit-button hyozan-button active'> Trend </button>
          </div>
          
        </div>
      </div>
    );
  }

  function LeadFlowView({leadData}){
    const [viewType, setViewType] = useState('SANKEY');
    var component = null;

    if (leadData.length <= 0)
      return;

    switch(viewType){
      case 'SANKEY':
        component = (<LeadFlowSankey leadData={leadData} />);
        break;
      case 'BARCHART':
        component = (<LeadFlowTrend leadData={leadData} />);
        break;
      case 'TABLE':
      default:
        component = (<LeadFlowTable leadData={leadData} />);
        break;
    }
    return (
      <div>
        <ViewButtons handler={setViewType} />
        {component}
      </div>
    )
  }

  function makeTotals(leadData){
    const totals = {};
    let   grandTotal = 0;
    leadStatusVals.map((l)=>{totals[l]=0});
    leadData.map((row)=>{
      row.statusCounts.map((c)=>{totals[c.status]+=c.count; grandTotal += c.count});
    });

    return([totals,grandTotal]);

  }

  function LeadFlowSummary({leadData}){
    /**
    const totals={};
    let grandTotal=0;
    leadStatusVals.map((l)=>{totals[l]=0});
    leadData.map((row)=>{
      row.statusCounts.map((c)=>{totals[c.status]+=c.count; grandTotal += c.count});
    });
    **/
    const [totals, grandTotal] = makeTotals(leadData); 
    
    return (
      <tr className='analytics-totals' key='12345' >
        <td>Total  </td>
        <td>{totals.INITIAL}    </td>
        <td>{totals.ASSIGNED}     </td>
        <td>{totals.CONTACTED}    </td>
        <td>{totals.WARM}       </td>
        <td>{totals.CONVERTED}    </td>
        <td>{totals.LOST}       </td>
        <td>{totals.COLD}       </td>
        <td>{totals.NOTCONTACTABLE} </td>
        <td>{grandTotal}      </td>
      </tr>
    )
  }

  function LeadFlowViewHeader(){
    return (
      <thead className='hyozan-table-header'>
        <tr className='hyozan-table-header-tr'>
          <th> Date       </th>
          <th> Inital     </th>
          <th> Assigned     </th>
          <th> Contacted    </th>
          <th> Warm       </th>
          <th> Converted    </th>          
          <th> Lost       </th>          
          <th> Cold       </th>          
          <th> Not<br/>contactable</th>          
          <th> total      </th>          
        </tr>
      </thead>
    );
  }

  function LeadFlowRow({rowVal}){
    function getCount(key, vals){
      for(let i=0; i < vals.length;i++)
        if(vals[i].status === key)
          return vals[i].count;
      
      return 0;
    }
    
    return (
      <tr key={rowVal.date} >
        <td>{rowVal.date}  </td>
        <td>{getCount('INITIAL',  rowVal.statusCounts)}</td>
        <td>{getCount('ASSIGNED',   rowVal.statusCounts)}</td>
        <td>{getCount('CONTACTED',  rowVal.statusCounts)}</td>
        <td>{getCount('WARM',     rowVal.statusCounts)}</td>
        <td>{getCount('CONVERTED',  rowVal.statusCounts)}</td>
        <td>{getCount('LOST',     rowVal.statusCounts)}</td>
        <td>{getCount('COLD',     rowVal.statusCounts)}</td>
        <td>{getCount('NOTCONTACTABLE',rowVal.statusCounts)}</td>
        <td>{rowVal.totalLeads}</td>
      </tr>
    )
  }
}
