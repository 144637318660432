import './css/JobManager.css'

import { useEffect, useState } from 'react';

import  DBCache  from './DBCache'; 
import  CommonValidations  from './Validations'
import  HyozanForm from './HyozanForm';

import { useAppContext } from './Context';
import TableHeader from './TableHeader';
import Waiter from './Waiter';


export default function JobManager(){
  const { addStatusMessage } = useAppContext();

  const [showAddJob, setShowAddJob] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [jobToManage, setJobToManage] = useState(null);
  const [jobSelected, setJobSelected] = useState(null);
  const [siteList, setSiteList] = useState([]);
  const [stagingList, setStagingList] = useState([]);
  const [waiting, setWaiting] = useState(false);
   
  const db = new DBCache();
  const cv = new CommonValidations();

  useEffect(startup,[]);
  //useEffect(dbFetchJobList,[]);
  //useEffect(dbFetchSiteList,[]);
  function startup(){
    setWaiting(true);
    dbFetchJobList();
    dbFetchSiteList();
  }

  function dbFetchJobList() {
    setWaiting(true);
    function handler(list){
      setWaiting(false);
      setJobList(list);
    }
    db.getJobList(handler);
  }

  function dbFetchSiteList() {
    setWaiting(true);
    function handler(list){
      setWaiting(false);
      setSiteList(list);
    }
    db.getSiteList(handler);
  }

  function downloadJobAttachment(event) {
    // console.log("Download of " + event.target.id + " requested");
    db.getFileFromBucket(event.target.id);
  }

  function startWork(jobVals)
  {

    // console.log("Request to start work on jobID", jobID);
    if (window.confirm("Job Status for "+ jobVals.jobName + " will be changed to WORKING and this job will be assigned to you.")){
      // console.log("Proceeding");
      db.setJobStatus(jobVals.jobID, "WORKING"); 
      setJobToManage(null);
    } 
  }

  function cancelJob(jobVals){
    if (window.confirm("Are your sure you want to cancel job: " + jobVals.jobName + "? This action cannot be undone!!!")) {
      db.setJobStatus(jobVals.jobID, "CANCELLED");
      setJobToManage(null);
    }
  }

  function getJobName(jobID) {
    for (let i=0; i < jobList.length; i++){
      if (jobList[i].jobID === jobID)
        return jobList[i].jobName;
    }
    return "not-found"; // Ideally should never happen. TODO - Add an alarm here.
  }

  function AddJobForm(){
    const [formData, setFormData] = useState({
      jobName:'',
      description:'',
      siteName:'',
      requirements:'',
      attachmentList:[]
    });
    const [waiting, setWaiting] = useState({upload:'notstarted',jobcreate:'notstarted'});    // States here are: 'notstarted','waiting','done'

    const handleChange = (e) => {
      // console.log("Change called @ ", Date.now());
      const {name, value } = e.target;
      setFormData({...formData, [name]:value});
      setShowAddJob(false);
    };

    const jobCreationResultHandler = (res) => {
      console.log("Got result", res);
      addStatusMessage(JSON.stringify(res), 'warn'); // TODO - Look at res, interpret text and show the correct values
      let newwaiting = {...waiting, jobcreate:'done'};
      setWaiting(newwaiting);
    };

    const uploadResultHandler = (res) => {
      console.log("Upload result was ", res);
      addStatusMessage(JSON.stringify(res), 'warn'); // TODO - Look at res, interpret text and show the correct values
      let newwaiting = {...waiting, upload:'done'};
      setWaiting(newwaiting);
    };

    async function handleSubmit(vals){
      console.log(vals);

      // TODO :: Add validations

      let newstat = {upload:'waiting', jobcreate:'waiting'}
      setWaiting(newstat);
      const jobIDVal = cv.getUniqueID();
      console.log('Job ID is ' + jobIDVal);

      if ('attachments' in vals && Array.isArray(vals.attachments) && (vals.attachments.length > 0))
        await db.uploadToBucket(jobIDVal, vals.attachments, "A" , uploadResultHandler);

      db.createNewJob({
        jobID: jobIDVal,
        jobName: vals.jobname,
        jobDescription: vals.description,
        siteName : vals.siteName,
        jobRequirements: vals.requirements,
        attachments: vals.attachments ?? []
        },
        jobCreationResultHandler       // This will be called back with a structure informing what happened to the site creation
      );

      setShowAddJob(false);
      
    }

    const handleCancel = (e) => {
      setFormData({jobname:'', description:''}); // reset values after submission
      setShowAddJob(false);
    }

    const getSiteList = () => {
      const retList = [];
      siteList.map((s) => {
          (s.siteStatus === 'ACTIVE') && 
          (s.siteName.slice(0,2) !== 'jj') && 
          (!(/^staging/.test(s.siteName))) &&
          retList.push({ID: s.siteName, Text: s.siteName});
      })
      return retList;
    }

    const vals={
      FormName: 'Add a new job',
      Fields :[
        {
          Label : 'Job name',
          Explanation: 'Enter a unique name for the job (eg. CarLoan-23-02-2024) '+
                  'This must be unique, if there is another job with the same name, the job '+
                  'will not be accepted. Please use only letters, numbers and hyphen (-) in  '+
                  'the job name. Spaces and special characters will not be allowed here',
          InputType : 'INPUT',
          onChange: handleChange,
          Prompt: {
            Type: 'INFO',
            Label: 'Please input the job name...'
          },
          Value : formData.jobName,
          ID : 'jobname',
          InitValue : 'Site-Update-23-03-2024..'
        },
        {
          Label : 'Job Description',
          Explanation: 'Enter a description for this job',
          InputType : 'INPUT',
          onChange: handleChange,
          Prompt: {
            Type: 'INFO',
            Label: 'Please input the job description...'
          },
          Value : formData.description,
          ID : 'description',
          InitValue : 'A brief description of this job'
        },
        {
          Label : 'Site',
          Explanation: 'Choose the site where you would like these changes to be made.\
          In case you want to make changes to multiple sites, kindly create multiple job requests.',
          InputType: 'LIST',
          Options: getSiteList(),
          onChange: handleChange,
          Prompt: {
            Type: 'INFO',
            Label: 'Please choose a site'
          },
          Value: formData.siteName,
          ID: 'siteName',
          InitValue: null
        },
        {
          Label: 'Requirements',
          Explanation: 'Please describe the change you would like in as much detail as possible. You can also \
              attach documents in word, excel, PDF or any images to clarify the requirement. Hyozan staff will revert \
              to you based on your requirements and you can always refine these as we go along.',
          InputType: 'TEXTAREA', 
          Value : formData.requirements,
          ID: 'requirements',
          InitValue : 'Add a car loan section to the website as per attached...'
        },
        {
          Label: 'Attachments',
          Explanation: 'Please attach any documents to further clarify this requirement. These files will be attached to the requirements for this job. If you want\
            to remove any of these, please click on the delete icon. Files cannot exceed 10MB in size.',
          InputType: 'FILELIST-WITH-BUTTON',
          ID: 'attachments',
          Text: 'Attach'          
        }
      ],
      onSubmit : handleSubmit,
      onCancel : handleCancel,
      SubmitText: "Create Job",
    };

    if (waiting.upload === 'done' && waiting.jobcreate === 'done')
      setShowAddJob(false);

    return (
      <HyozanForm FormVals={vals} />
    )
     
  }

  const setShowAddJobTrue = () => {setShowAddJob(true)};
  const refreshJobList = () => {
    function handler(list){
      setWaiting(false);
      setJobList(list);
    }
    setWaiting(true);
    db.getJobList(handler, true); 
  }; // Force a refresh

  var i=0;

  if(jobToManage === null)
    return(
    <div className='hyozan-workspace'>
      { !showAddJob &&
      <div className='hyozan-dashboard'>
        <div className='dashboard-header'> 
          <div className="dashboard-title">
            <p>List of jobs</p> 
          </div>
          
          <div className='dashboard-spacer'></div>

          <div className='dashboard-controls'>
            <div className="refresh">
              <div title="refresh" onClick={refreshJobList}>
                <img src='refresh.png' alt="refresh"/>
              </div>
            </div>
            <div className="control-button">
              <button title="create job" className='hyozan-button active' onClick={setShowAddJobTrue}>New Job</button>
            </div>
          </div>
        </div>      
        <table className='hyozan-table'>
          <JobListHeader />
          <tbody>
            {jobList &&
              jobList.map((job) => {return(
                  <JobListRow jobVals={job} key={i++} />
              )}) 
            }
          </tbody>
        </table>      
      </div>
      }

      <div>
        {showAddJob &&
          <div>
            <AddJobForm />
          </div>
        }
      </div>
      {waiting && <Waiter />}
    </div>
    );
    else 
      return(
    <div className='hyozan-workspace'>
      <div className='hyozan-dashboard'>
          <div>
            <JobDetails jobVals={jobToManage}  />
          </div> 
      </div>
      {waiting && <Waiter />}
    </div>
      );
      
  

  function JobDetails({jobVals})
  {

    return(
      <div className='hyozan-form'>
        <JobHeader jobVals={jobVals} />
        <JobInitialVals jobVals={jobVals} />
        <JobNotes jobVals={jobVals} />
        <JobAddNote jobVals={jobVals}/>
        <JobButtons jobVals={jobVals} />
      </div>
    );
  }

  function JobAddNote({jobVals}) {
    const [note, setNote] = useState({notePresent:false, noteDetails: {}});
    return(      
      <div className='hyozan-form-display-envelope'>
        { 
          ( note.notePresent === false) ?
            < JobAddNoteButton 
              jobVals={jobVals} 
              noteVals={note}
              setNoteVals={setNote} 
            /> :
            < JobAddNoteForm 
              jobVals={jobVals}
              note={note}
              setNoteVals={setNote} 
            />
        }    
      </div>
    );
  }

  
  function JobAddNoteForm({jobVals, note, setNoteVals}) {
    function handleNoteSubmit(formData) {

      const uploadResultHandler = (res) => {
        console.log("Upload result was ", res);
        addStatusMessage(JSON.stringify(res), 'warn'); // TODO - Look at res, interpret text and show the correct values
      };


      // TODO: Add validations on entry values. File name , file sizes etc.
      console.log("Note submit pressed");
      console.log(formData);
      const noteNum = ("jobNotes" in jobVals) ? jobVals.jobNotes.length + 1 : 0;
      const atts = [];  // Attachments

      db.uploadToBucket(jobVals.jobID, formData.attachments, 'N'+noteNum, uploadResultHandler);


      for (let i=0; formData.attachments && i < formData.attachments.length; i++){
        atts.push(db.makeFilename(jobVals.jobID, 'N' + noteNum, i, formData.attachments[i].name));
      }


      const newNote = {
        noteType: "REQUIREMENTS",
        noteRequirements: formData.requirements,
        noteAttachments: atts,
        noteCreated: Date.now(),
        noteCreatedBy: 'myusername'
      };
      console.log(newNote);
      db.AddNoteToJob(jobVals.jobID,newNote, ()=>{setNoteVals({notePresent : false, noteDetails:{}})});

    }

    function handleNoteCancel(e) {
      e.preventDefault();
      setNoteVals({...note, notePresent: false});
      
    }

    const vals={
      FormName: 'Add a note',
      Fields :[
        {
          Label: 'Updated Requirements',
          Explanation: 'Please add on or modify the current requirements.',
          InputType: 'TEXTAREA', 
          Value : note.requirements,
          ID: 'requirements',
          InitValue : 'Add the following extra promotion elements....'
        },
        {
          Label: 'Attachments',
          Explanation: 'Please attach any documents to further clarify this requirement. '+
            'These files will be attached to the requirements for this job. If you want '+
            'to remove any of these, please click on the delete icon. '+
            'Files cannot exceed 10MB in size. Please note previously added attachments cannot be removed',
          InputType: 'FILELIST-WITH-BUTTON',
          ID: 'attachments',
          Text: 'Attach'          
        }
      ],
      onSubmit : handleNoteSubmit,
      onCancel : handleNoteCancel,
      SubmitText: "Append Note",
    };

    return (
      <HyozanForm FormVals={vals} />
    )
  }

  
  function JobListHeader(){
    const headers=[
      {
        label:'    ',
        sort:false,
        fieldname:null,
      },
      {
        label: 'Name',
        sort:true,
        fieldname:'jobName',
      },
      {
        label: 'Description',
        sort:true,
        fieldname:'jobDescription',
      },
      {
        label:'Site',
        sort:true,
        fieldname:'siteName',
      },
      {
        label:'Status',
        sort:true,
        fieldname:'jobStatus',
      },
      {
        label:'Manage',
        sort:false,
        fieldname:null,
      },
    ];

    return(
      <TableHeader 
        fields={headers}
        data={jobList}
        setter={setJobList}
      />

    );
  }

  function JobListRow({jobVals}){   
    const job = jobVals;
    const url = "https://" + job.jobName + "/index.html";
    var   tdClass;
    switch (job.jobStatus) {
      case 'ACTIVE': tdClass = 'job-active'; break;
      case 'INITIAL' : tdClass = 'job-initial'; break;
      default: tdClass = 'job-unknown'; break;
    }
    return (
      <tr 
        key={job.jobName} 
        id={job.jobID} 
        className={job.jobID===jobSelected?'hyozan-table-row selected-row':'hyozan-table-row'} 
        onDoubleClick={handleJobClick}>

        <td>
          <input
            type='radio'
            checked={job.jobID === jobSelected}
            onChange={()=>{}}
            onClick={()=>{setJobSelected(job.jobID)}}
          />
        </td>

        <td className={tdClass}>{job.jobName}</td>
        <td>{job.jobDescription ? job.jobDescription.slice(0,30) : ""}</td>
        <td>{job.siteName}</td>
        <td>{job.jobStatus? job.jobStatus : "UNKNOWN"}</td>
        <td>{job.jobID === jobSelected &&
          <button type='button' onClick={()=>{setJobToManage(job)}}>Manage</button>
            }
        </td>
      </tr>
    )
  }

  function handleJobClick(event) {
    // console.log("Clicked : ", event.target, event.target.tagName, event.target.parentNode.id);
    db.getJobDetails(event.target.parentNode.id , setJobToManage);
  }
  
  function JobHeader({jobVals}){
    return(
      <div className='form-heading'>
        <div className='form-header-holder'>
          <label className='hyozan-form-title'> Job {jobVals.jobName} : Status <em> {jobVals.jobStatus} </em></label>
        </div>
      </div>
    );
  }

  function JobInitialVals({jobVals}){
    return(
      <div className='hyozan-form-display-envelope'>
        <div className='hyozan-form-display-segment'>

          <div className='hyozan-form-display-pair'>
            <div className='hyozan-form-display-label'>
              <label> Description</label>
            </div>
            <div className='hyozan-form-display-value'>
              <p> { jobVals.jobDescription}</p>
            </div>
          </div>

          <div className='hyozan-form-display-pair'>
            <div className='hyozan-form-display-label'>
              <label> Requirements</label>
            </div>
            <div className='hyozan-form-display-value'>
              <pre> { jobVals.jobRequirements}</pre>
            </div>
          </div>

          <div className='hyozan-form-display-pair'>
            <div className='hyozan-form-display-label'>
              <label> Initiated on</label>
            </div>
            <div className='hyozan-form-display-value'>
              <p> { cv.formattedDate(jobVals.jobInitiatedOn) }</p>
            </div>
          </div>

          <div className='hyozan-form-display-pair'>
            <div className='hyozan-form-display-label'>
              <label> Staging Website </label>
            </div>
            <div className='hyozan-form-display-value'>
              <p>
                {"stagingSite" in jobVals ? 
                  <a 
                    href={"https://" + jobVals.stagingSite + "/index.html"} 
                    target="_blank" 
                    className='hyozan-form-file-link'> 
                      { jobVals.stagingSite }
                  </a> 
                  :
                  <p> Staging site not created so far... </p>
                }
              </p>
            </div>
          </div>

          <div className='hyozan-form-display-pair'>
            <div className='hyozan-form-display-label'>
              <label> Attachments</label>
            </div>
            <div className='hyozan-form-display-value'>
              <div className='hyozan-form-file-list'>
                {("attachments" in jobVals) ? Array.isArray(jobVals.attachments) && 
                  jobVals.attachments.map((filename)=> {
                    return(
                      <div key={filename} className='hyozan-form-file-link-holder'>
                        <a href='#' className='hyozan-form-file-link' id={filename} onClick={downloadJobAttachment}> 
                          {db.getFilenameFromBucketname(filename)} 
                        </a>
                      </div>
                    )
                }) :
                  <div className='hyozan-form-display-value'> No attachments </div>
                }
                {/* TODO: Possible issue if more than 10 filenames - the slice logic will fail... */}
              </div>
            </div>
          </div>


        </div>
      </div>

    );
  }
  function JobNotes({jobVals}){

    return(('jobNotes' in jobVals && jobVals.jobNotes.map((note,num) => {
      return(
      <div className='hyozan-form-display-envelope' key={num}>
        <div className='hyozan-form-display-segment'>

          <div className='hyozan-form-display-pair'>
            <div className='hyozan-form-display-label'>
              <p> Note Type : {note.noteType} </p>
            </div>
          </div>

          <div className='hyozan-form-display-pair'>
            <div className='hyozan-form-display-label'>
              <p> Additional / modified requirements </p>
            </div>
            <div className='hyozan-form-display-value'>
              <pre> {note.noteRequirements} </pre>
            </div>
          </div>

          {("noteAttachments" in note)  && (note.noteAttachments.length > 0) &&
            <div className='hyozan-form-display-pair'>
              <div className='hyozan-form-display-label'>
                <label> Attachments</label>
              </div>
              <div className='hyozan-form-display-value'>
                <div className='hyozan-form-file-list'>
                  {note.noteAttachments.map((filename)=> {
                    return(
                      <div key={filename} className='hyozan-form-file-link-holder'>
                        <a href='#' className='hyozan-form-file-link' id={filename} onClick={downloadJobAttachment}> 
                          {db.getFilenameFromBucketname(filename)} 
                        </a>
                      </div>
                    )
                  })}                  
                </div>
              </div>
            </div>
          }


        </div>
      </div>)
    })))
  }

  function appendNote(jobVals){
    if ('notes' in jobVals){
      console.log("Notes found");
    } else {
      console.log("No notes so far...");
    }
  }
  
  function JobAddNoteButton({jobVals, noteVals, setNoteVals}){
    return(
      <div className='hyozan-form-button-holder'>
        <button  className='hyozan-form-submit-button hyozan-button secondary' 
            onClick={()=>{setNoteVals({...noteVals, notePresent:true})}}>
              Add Note
        </button>
      </div>
    );
  }

  /*
  function verifyStagingCreation( jobVals, stagingSiteName, jobArn) {
    let intervalID;

    console.log("Verifying the creation status for " + stagingSiteName + " with Arn " + jobArn);
    
    intervalID = setInterval(checkStatus, 5000);

    function checkStatus(){
      const str = db.getJobStatus(jobArn, callbackForStatus);
    }

    function callbackForStatus(vals) {
      if (vals) {
        console.log(vals);
        const jobStatus = vals.executionStatus.status;
        if (jobStatus != 'RUNNING') {
          clearInterval(intervalID);
          addStatusMessage("Staging creation is still pending", 'warn');
        }
        if (jobStatus == 'SUCCEEDED') {
          db.setJobStaging(jobVals.jobID,stagingSiteName, setJobStagingCallback);
          addStatusMessage("Staging site creation succeeded", 'success');
        } else {
          console.log("Staging creation job failed");
          addStatusMessage("Staging creation failed", "error");
        }
      } else {
        clearInterval(intervalID);
        console.log("Error in getting staging job creation");
        addStatusMessage("Unable to get status of staging creation", "error");
      }
    }

    function setJobStagingCallback(vals) {
      console.log("DB Status callback ", vals);
      addStatusMessage(JSON.stringify(vals), 'warn'); // TODO - Look at res, interpret text and show the correct values      
    }
  }
  */

  function createStagingSite(jobVals){
    /* new logic for creating staging:
      * look thru the sites array and see staging sites associated with the current site
      * Pick the first one that doesnt have a job associated with it.
      * associate this job with said staging site
      * If there is no free staging site, fail.
      */

    // Look for the first free staging site.
    const stagingRegex = new RegExp("^staging\\d+\\."+jobVals.siteName.replace(/\./g, '\\.'));
    console.log('regex for site check is', stagingRegex);
    
    let i = 0;
    let stagingSiteName = '';
    for(i=0;i < siteList.length; i++){
      console.log(`testing ${siteList[i].siteName}`);
      if((stagingRegex.test(siteList[i].siteName)) && 
        (!siteList[i].hasOwnProperty('jobAttached'))){
        // Assign the site name here.
        setWaiting(true);
        stagingSiteName = siteList[i].siteName;
        db.setJobStaging(jobVals.jobID, stagingSiteName, stagingCallback);
        break;
      }
    }
    if(i >= siteList.length){
      window.alert('Unable to find staging site');
    }

    function stagingCallback(result){
      setWaiting(false);
      console.log("Staging callback result : " , result);
      console.log("sitename is ", stagingSiteName);
      if (result.result == 'success'){
        console.log("Result success , monitoring job")
        addStatusMessage("Staging result success","success");
        //verifyStagingCreation(jobVals, stagingSiteName, result.Arn)
      }
      else {
        console.log("Staging creation failed");
        addStatusMessage("Staging creation failed", "error");
      }
    }
  }

  function syncContent(jobVals) {
    console.log("Sync Content Invoked at ", Date.now());

    if (!("stagingSite" in jobVals)) // Sanity check for staging site.
      return;
    
    const gitBranch = "master"; // TODO::: For now. In future, will have a specific branch 
                  // to be synced . The branch name will need to be the same as 
                  // the jobID ?

    if (window.confirm("Will now proceed to sync \n\nbranch: " + gitBranch + " \nto \n\nstaging site: https://" + jobVals.stagingSite)){
      console.log("Confirmed - proceeding");
      db.syncContent(jobVals.jobID, gitBranch, syncHandler);
    }
    else
      console.log("Cancelled sync");

    function syncHandler(result) {
      console.log(result); // TODO : Show a toast.
      addStatusMessage("Sync result:"+JSON.stringify(result));
    }
  }

  function reqClarification(jobVals) {
    db.setJobStatus(jobVals.jobID, "CLARIFY");
    setJobToManage(null);
  }

  function startReview(jobVals) {
    db.setJobStatus(jobVals.jobID, "REVIEW");
    setJobToManage(null);
  }

  function deployToProduction(jobVals) {
    console.log("Deploy to production ", Date.now());

    // TODO - Validate job is approved and the current user has the appropriate rights to deploy...

    if (window.confirm("Will now proceed to deploy from staging site: https://" + jobVals.stagingSite + " to production site")){
      console.log("Confirmed - proceeding");
      db.deployToProduction(jobVals.jobID, deployHandler); 
    }
    else
      console.log("Cancelled sync");

    function deployHandler(result) {
      console.log(result); // TODO : Show a toast.
      addStatusMessage("Sync result:"+JSON.stringify(result));  
    }
  }
  

  function JobButtons({jobVals}){
    function AddClarificationsButton({jobVals}){
      return(
        <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-submit-button hyozan-button active' 
              onClick={(e)=>{
                      e.preventDefault(); 
                      db.setJobStatus(jobVals.jobID,"CLARIFIED"); 
                      setJobToManage(null);
                      }
                  }
          >
                Continue Work
          </button>
        </div>
      );
    }

    function AddReviewButton({jobVals}){
      // TODO - Factor in workflow to see if this is the final reviewer 
      // or needs to go to next person. Better done at back end ?
        return(
          <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-submit-button hyozan-button active' 
              onClick={(e)=>{
                      e.preventDefault(); 
                      db.setJobStatus(jobVals.jobID,"REVIEWING"); 
                      setJobToManage(null);
                      }
                  }
          >
                  Add Review
            </button>
          </div>
        );
    }

    function ApproveButton({jobVals}){
      // TODO - Factor in workflow to see if this is the final reviewer 
      // or needs to go to next person. Better done at back end ?
        return(
          <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-submit-button hyozan-button active' 
              onClick={(e)=>{
                      e.preventDefault(); 
                      db.setJobStatus(jobVals.jobID,"DEPLOYABLE"); 
                      setJobToManage(null);
                      }
                  }
          >
                  Approve
            </button>
          </div>
        );      
    }
    function ApproveDeploy({jobVals}){
      // TODO - Factor in workflow to see if this is the final reviewer 
      // or needs to go to next person. Better done at back end ?
        return(
          <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-submit-button hyozan-button active' 
              onClick={(e)=>{
                      e.preventDefault(); 
                      deployToProduction(jobVals);
                      setJobToManage(null);
                      }
                  }
          >
                  Deploy to production
            </button>
          </div>
        );      
    }
    function CancelJobButton({jobVals}){
      return(
        <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-submit-button hyozan-button danger' 
              onClick={(e)=>{e.preventDefault(); cancelJob(jobVals)}}>
                Cancel Job
          </button>
        </div>
      );
    }
    
    function ClarifiedButton({jobVals}){
      return(
        <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-submit-button hyozan-button active' 
              onClick={(e)=>{e.preventDefault(); startWork(jobVals)}}>
                Submit Clarification
          </button>
        </div>
      )      
    }

    function ClarifyButton({jobVals}){
      return(
        <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-submit-button hyozan-button secondary' 
              onClick={(e)=>{e.preventDefault(); reqClarification(jobVals)}}>
                Request Clarification
          </button>
        </div>
      )      
    }

    function CloseButton({jobVals}){
      return(
        <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-cancel-button hyozan-button cancel' 
              onClick={()=>{setJobToManage(null)}}>
                Close
          </button>
        </div>
      );
    }

    function StartWorkButton({jobVals}){
      return(
        <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-submit-button hyozan-button active' 
              onClick={(e)=>{e.preventDefault(); startWork(jobVals)}}>
                Start work
          </button>
        </div>
      )
    }

    function SubmitForReviewButton({jobVals}){
      return(
        <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-submit-button hyozan-button active' 
              onClick={(e)=>{e.preventDefault(); startReview(jobVals)}}>
                Submit For Review
          </button>
        </div>
      )
    }

    function CreateStagingButton({jobVals}){

      // If the staging site is already created, dont display this button
      if (("stagingSite" in jobVals) && (jobVals.stagingSite.length > 0)) {
        return;
      } else {
        return(
          <div className='hyozan-form-button-holder'>
            <button  className='hyozan-form-submit-button hyozan-button active' 
                onClick={(e)=>{e.preventDefault(); createStagingSite(jobVals)}}>
                  Create Staging
            </button>
          </div>
        )
      }
    }

    function SyncContentButton({jobVals}){

      // If the staging site is not present, dont display this button
      if (!("stagingSite" in jobVals))  {
        return;
      } else {
        return(
          <div className='hyozan-form-button-holder'>
            <button  className='hyozan-form-submit-button hyozan-button active' 
                onClick={(e)=>{e.preventDefault(); syncContent(jobVals)}}>
                  Sync Content
            </button>
          </div>
        )
      }
    }

    function SendBackButton({jobVals}){
        return(
          <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-submit-button hyozan-button secondary' 
              onClick={(e)=>{
                      e.preventDefault(); 
                      db.setJobStatus(jobVals.jobID,"CLARIFIED"); 
                      setJobToManage(null);
                      }
                  }
          >
                  Send back
            </button>
          </div>
        );      
    }

    const mainAction = {
      REQUESTED :   [StartWorkButton, CancelJobButton, CloseButton],
      WORKING :     [SubmitForReviewButton, ClarifyButton, CreateStagingButton, SyncContentButton, CloseButton],
      CLARIFY :     [AddClarificationsButton, CancelJobButton, CloseButton],
      CLARIFIED:    [SubmitForReviewButton, ClarifyButton, CreateStagingButton, SyncContentButton, CloseButton],
      REVIEW:     [AddReviewButton, SendBackButton, ApproveButton, CloseButton],
      REVIEWING:    [AddReviewButton, SendBackButton, ApproveButton, CancelJobButton, CloseButton],
      DEPLOYABLE:   [ApproveDeploy, SendBackButton, CancelJobButton, CloseButton],
      DEPLOYED:     [CloseButton],
      CANCELLED:    [CloseButton]
    }
    const buttonList = mainAction[jobVals.jobStatus];

    return (
      <div className='hyozan-form-button-area'>
        {buttonList.map((Btn,i) => {return( <Btn key={i} jobVals={jobVals} />) })}
      </div>
    )     
  }
}

