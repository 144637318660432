import './css/Workspace.css';
import SiteManager from './SiteManager';
import UserManager from './UserManager';
import JobManager  from './JobManager' ;
import LoginManager from './LoginManager';
import LeadList     from './LeadList';
import LeadsFlow   from './LeadAnalytics';
import LeadSearch from  './LeadSearch';
import CallWindow  from './CallWindow';
import Slideshow  from  './Slideshow';
import Productivity from './Productivity';


import { useAppContext } from './Context';
import {  useEffect } from 'react';
import  DBCache  from './DBCache'; 


function UnderConstruction({name}){
  return(
        <div className='hyozan-workspace'>
          <div className='construction-body'>
            <img 
              src='construction.png'
              className='construction-img'
              alt='Under Construction'
              id='underConstruction' />
            <p className='construction-text'>
              This is the workspace for {name}
            </p>
          </div>
        </div>
      );
}

const slides=[
  '/hyozan1.html',
  '/hyozan2.html',
  '/hyozan3.html',
  '/hyozan4.html',
];
const music='';

function Workspace({menuItem}) {

  const {
    loginToken, 
    setLogin, 
    callWindow}               = useAppContext();
  const db                    = new DBCache();
  useEffect(() => {
    const loginVals           = localStorage.getItem('hajime-login-tokens');
    if (loginVals != null){
      setLogin(JSON.parse(loginVals));
      db.setUserVals(JSON.parse(loginVals));
    }
  },[]);

  var wsComponent             = null;

  switch(menuItem){
    case 'LeadsMenu'   :
      wsComponent=(<LeadList />)
      break;
    case 'DeptsMenu'  :   
      wsComponent=(<p> We will add department maintenance here</p>)
      break;
    case 'JobsMenu'    :   
      wsComponent=(<JobManager />)        
      break;
    case 'UsersMenu'   :   
      wsComponent=(<UserManager />)
      break;
    case 'SitesMenu'   :   
      wsComponent=(<SiteManager />);
      break;
    case 'Login'     :
      wsComponent = (<LoginManager />)
      break;
    case 'LeadsFlow'   :
      wsComponent = (<LeadsFlow />)
      break;
    case 'LeadSearch' :
      wsComponent = (<LeadSearch />);
      break;
    case 'LeadsProductivity':
      wsComponent = (<Productivity />);
      break;
    case 'DashboardMenu' :
    case 'RolesMenu'   :   
    case 'PermissonsMenu':
    case 'WorkflowsMenu' :
    case 'HistoryMenu'   :   
    case 'CleanupMenu'   :   
    case 'LogsMenu'    :   
    case 'IssuesMenu'  :   
    case 'TaggingMenu'   : 
    case 'LeadsPipeline' :
    case 'LeadsCycle'  :      
    case 'AnalyticsMenu' :          
      wsComponent = (<UnderConstruction name={menuItem} />)   
      break;     
    case 'Home' :
    default:
      wsComponent = (<Slideshow urls={slides} interval={10} audio={music}/>)   
      break;     
    
  }
  return(
    <div className='workspace-body' >
      {callWindow && <CallWindow />}
      {wsComponent}
    </div>
  );
}

export default Workspace;
