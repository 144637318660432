import './css/LeadManager.css'

import { useState } from 'react';

import  DBCache  from './DBCache'; 
import  CommonValidations  from './Validations'
import  HyozanForm from './HyozanForm';
import  CallWindow  from './CallWindow';
import { useAppContext } from './Context';
import  TabbedEdit from './TabbedEdit';
import  FileLink from './FileLink';
import  PhoneCall from './PhoneCall';

const dummyHistory = [
  {
    callDateTime: "2024-12-08T14:23:37",
    callDuration: 321,
    callSummary: "Discussed account details and next steps.",
    transcript: [
      { agentName: "Agent John", text: "Hi, I’m calling regarding your account update. Can we discuss the details?" },
      { customerName: "Customer Alice", text: "Sure, please go ahead." },
      { agentName: "Agent John", text: "We’ve outlined the next steps in the email sent to you earlier today." },
      { customerName: "Customer Alice", text: "Got it, I’ll review the email and follow up if I have questions." }
    ],
    recordingLink: "https://example.com/recordings/call1.mp3",
  },
  {
    callDateTime: "2024-12-09T10:45:12",
    callDuration: 567,
    callSummary: "Resolved billing issue and confirmed refund.",
    transcript: [
      { agentName: "Agent Sarah", text: "Hello, I see there was an issue with your billing. Let’s resolve this." },
      { customerName: "Customer Bob", text: "Yes, I was overcharged on my last invoice." },
      { agentName: "Agent Sarah", text: "We’ve identified the issue, and a refund has been processed. You’ll receive it in 5 days." },
      { customerName: "Customer Bob", text: "Thanks for your help!" }
    ],
    recordingLink: "https://example.com/recordings/call2.mp3",
  },
  {
    callDateTime: "2024-12-10T09:15:46",
    callDuration: 142,
    callSummary: "Scheduled a call-back for tomorrow.",
    transcript: [
      { agentName: "Agent Mike", text: "Hi, I wanted to follow up on your request. Can we schedule a callback?" },
      { customerName: "Customer Nina", text: "Yes, tomorrow at 3 PM works for me." },
      { agentName: "Agent Mike", text: "Great! I’ve scheduled the call for tomorrow. See you then." },
      { customerName: "Customer Nina", text: "Thank you!" }
    ],
    recordingLink: "https://example.com/recordings/call3.mp3",
  },
  {
    callDateTime: "2024-12-11T16:03:28",
    callDuration: 876,
    callSummary: "Explained product features and benefits.",
    transcript: [
      { agentName: "Agent Jane", text: "Thank you for your interest in our product. Let me explain the key features." },
      { customerName: "Customer Carl", text: "Sure, I’d like to know about the free trial and support options." },
      { agentName: "Agent Jane", text: "Our product includes a 30-day free trial and 24/7 customer support." },
      { customerName: "Customer Carl", text: "That sounds good. I’ll give it a try!" }
    ],
    recordingLink: "https://example.com/recordings/call4.mp3",
  },
  {
    callDateTime: "2024-12-12T13:55:09",
    callDuration: 402,
    callSummary: "Customer requested additional documentation.",
    transcript: [
      { agentName: "Agent Alex", text: "Hi, we need some additional documents to proceed with your application." },
      { customerName: "Customer Ella", text: "What documents are required?" },
      { agentName: "Agent Alex", text: "I’ve emailed you a list. Please let us know if you need assistance." },
      { customerName: "Customer Ella", text: "Thanks, I’ll check the email and get back to you." }
    ],
    recordingLink: "https://example.com/recordings/call5.mp3",
  },
  {
    callDateTime: "2024-12-13T11:37:53",
    callDuration: 715,
    callSummary: "Reviewed the contract terms and conditions.",
    transcript: [
      { agentName: "Agent Rachel", text: "Let’s review the key terms of your contract." },
      { customerName: "Customer David", text: "Sure, I want to understand the cancellation policy." },
      { agentName: "Agent Rachel", text: "You can cancel anytime within 30 days for a full refund." },
      { customerName: "Customer David", text: "Got it, thank you!" }
    ],
    recordingLink: "https://example.com/recordings/call6.mp3",
  },
  {
    callDateTime: "2024-12-14T08:20:41",
    callDuration: 198,
    callSummary: "Confirmed payment receipt and next steps.",
    transcript: [
      { agentName: "Agent Kevin", text: "We’ve received your payment. Thank you." },
      { customerName: "Customer Olivia", text: "Great! What happens next?" },
      { agentName: "Agent Kevin", text: "Your application is being processed. You’ll receive a confirmation soon." },
      { customerName: "Customer Olivia", text: "Thanks for the update!" }
    ],
    recordingLink: "https://example.com/recordings/call7.mp3",
  },
  {
    callDateTime: "2024-12-14T19:48:30",
    callDuration: 631,
    callSummary: "Provided troubleshooting for the device.",
    transcript: [
      { agentName: "Agent Steve", text: "Let’s troubleshoot the issue. First, restart the device." },
      { customerName: "Customer Mia", text: "I’ve restarted it, but it’s still not working." },
      { agentName: "Agent Steve", text: "Try resetting the connectivity settings. I’ll guide you through it." },
      { customerName: "Customer Mia", text: "Okay, it’s working now. Thank you!" }
    ],
    recordingLink: "https://example.com/recordings/call8.mp3",
  }
];


export default function LeadManager({leadVals, closeHandler}){

  const [videoCall, setVideoCall]         = useState(false);
  const [phoneCall, setPhoneCall]         = useState(false);
  const [newNote, setNewNote] = useState(null);
  const { addStatusMessage } = useAppContext();

  const db = new DBCache();
  const cv = new CommonValidations();

  return(
    <div className='hyozan-form'>
      {
        phoneCall && 
          <PhoneCall 
            number={leadVals.leadVals.phoneNumber} 
            closeHandler={()=>setPhoneCall(false)}
            name={leadVals.leadVals.name}
            callHistory={dummyHistory}
          />
      }
      {
        videoCall && 
          <CallWindow closeHandler={()=>setVideoCall(false)}/>
      }
      <LeadHeader     leadVals={leadVals} />
      <LeadInitialVals  leadVals={leadVals} />
      <LeadNotes      leadVals={leadVals} />
      <LeadAddNote    leadVals={leadVals} />
      <LeadButtons    leadVals={leadVals} />
    </div>
  );

  function LeadAddNote({leadVals}) {
    const [note, setNote] = useState({notePresent:false, noteDetails: {}});
    return(      
      <div className='hyozan-form-display-envelope'>
        { 
          ( note.notePresent === false) ?
            < LeadAddNoteButton 
              leadVals={leadVals} 
              noteVals={note}
              setNoteVals={setNote} 
            /> :
            < LeadAddNoteForm 
              leadVals={leadVals}
              note={note}
              setNoteVals={setNote} 
            />
        }    
      </div>
    );
  }

  function LeadAddNoteForm({leadVals, note, setNoteVals}) {
    function handleNoteSubmit(formData) {

      const uploadResultHandler = (res) => {
        console.log("Upload result was ", res);
        addStatusMessage(JSON.stringify(res), 'warn'); // TODO - Look at res, interpret text and show the correct values
      };


      // TODO: Add validations on entry values. File name , file sizes etc.
      console.log("Note submit pressed");
      console.log(formData);
      const noteNum = ("leadNotes" in leadVals) ? leadVals.leadNotes.length + 1 : 0;
      const atts = [];  // Attachments

      db.uploadToBucket(leadVals.leadID, formData.attachments, 'N'+noteNum, uploadResultHandler);


      for (let i=0; formData.attachments && i < formData.attachments.length; i++){
        atts.push(db.makeFilename(leadVals.leadID, 'N' + noteNum, i, formData.attachments[i].name));
      }


      const newNote = {
        noteType    : "LEADNOTES",              // TODO:: This is a single type for now
        noteText    : formData.requirements,
        noteAttachments : atts,
        noteCreated   : Date.now(),               // Will be overridden at back end
        noteCreatedBy   : 'myusername'              // TODO: Get the actual username. But this will be overridden at back end anyhow
      };
      console.log(newNote);
      db.addNoteToLead(leadVals.leadID,newNote, ()=>{setNoteVals({notePresent : false, noteDetails:{}})});

    }

    function handleNoteCancel(e) {
      e.preventDefault();
      setNoteVals({...note, notePresent: false});
      
    }

    const vals={
      FormName  : 'Add a note',
      Fields    :[
        {
          Label       : 'Notes about this lead',
          Explanation   : 'Please mention any points regarding this lead.',
          InputType     : 'TEXTAREA', 
          Value       : note.requirements,
          ID        : 'requirements',
          InitValue     : 'He is very interested in car loan, the speed of processing is critical for him...'
        },
        {
          Label       : 'Attachments',
          Explanation   : 'Please attach any documents relevant to this lead. If you want'+
                    ' to remove any of these, please click on the delete icon. '+
                    'Files cannot exceed 10MB in size. Please note previously '+
                    'added attachments cannot be removed',
          InputType     : 'FILELIST-WITH-BUTTON',
          ID        : 'attachments',
          Text      : 'Attach'          
        }
      ],
      onSubmit  : handleNoteSubmit,
      onCancel  : handleNoteCancel,
      SubmitText  : "Append Note",
    };

    return (
      <HyozanForm FormVals={vals} />
    )
  }

  function LeadHeader({leadVals}){
    return(
      <div className='form-heading'>
        <div className='form-header-holder'>
          <label className='hyozan-form-title'> 
            Lead Details
          </label>
        </div>
      </div>
    );
  }

  function LeadPropensity({leadVals}){
    return(
      <div>
        <img
         className = 'lead-propensity-meter'
         src = 'meter_75.png'
         alt = 'warm'
         />
      </div>
    );
  }

  function MakePhoneCall({number}){
    function phoneCall(){
      setPhoneCall(true);
    }

    return(
      <img
        className = 'lead-contact-icon'
        src = 'phone.png'
        alt = 'phone call'
        onClick={phoneCall}
      />
    );
  }
  
  function MakeWhatsappCall({number}){
    return(
      <img
        className = 'lead-contact-icon'
        src = 'whatsapp.svg'
        alt = 'whatsapp call'
      />
    );
  }
  
  function MakeVideoCall({number}){
    function videoCall(){
      setVideoCall(true);
    }
    return(
      <img
        className = 'lead-contact-icon'
        src = 'video.png'
        alt = 'video call'
        onClick={videoCall}
      />
    );
  }

  function LeadPhoto({leadVals}){
    return(
      <img 
        className='lead-img' 
        src='lead_photo.svg' 
        alt='lead-photo' />
    );
  }

  function LeadHistory({leadVals}){
    return(
     <img 
        className='lead-history-img'
        src='heatmap.png'
        alt='browsing history' />
    );
  }


  function AddressBox({leadVals}){

    function addressLayout(addressVal) {
      const address = leadVals.leadVals.address[addressVal];
      return(
        <div className='lead-address-holder'>
          <div className='lead-address-line'>
            {
              address.lines.map((l,n) => {
                return(
                  <p key={n}> {l} </p>
                )})
            }
          </div>
          <div className='lead-address-locality'>
            {address.locality}
          </div>
          <div className='lead-address-lastline'>
            <div className='lead-address-city'>
              <span>{address.city},</span>
            </div>
            <div className='lead-address-state'>
              <span>{address.state},</span>
            </div>
            <div className='lead-address-zip'>
              <span> {address.zip} </span>
            </div>
          </div>
        </div>
      )
    }

    if(leadVals.leadVals.address )
      return(
        <div>
          <TabbedEdit 
            title='Address'
            tabs={leadVals.leadVals.address}
            layout={addressLayout}
          />
        </div>
      );
  }

  function LeadInitialVals({leadVals}){
    return(
      <div className='hyozan-form-display-envelope'>
        <div className='hyozan-form-display-segment'>

          <div className='lead-envelope'>
            <div className='lead-left'>
              <div className='lead-name-holder'>
                <div className='lead-propensity-box'>
                  <LeadPropensity leadVals={leadVals}/>
                </div>
                <span className='lead-name'>
                  {leadVals.leadVals.name ?? 'no name'}
                </span>
              </div>

              <div className='lead-phone-box'>
                <span className='lead-phone-number'>
                  {leadVals.leadVals.phone ?? 'no number'}
                </span>
                <div className='lead-contact-box'>
                  <MakePhoneCall number={leadVals.leadVals.phone} />
                  {/**<MakeWhatsappCall number={leadVals.leadVals.phone} />**/}
                  <MakeVideoCall number={leadVals.leadVals.phone} />
                </div>
              </div>

              <div className='lead-email-address-box'>
                <span className='lead-email-address'>
                  {leadVals.leadVals.emailaddress ?? 'email unknown'}
                </span>
                <a 
                  className='lead-send-mail' 
                  href={`mailto:${leadVals.leadVals.emailaddress??'none'}`}
                >
                  📧
                </a>
              </div>

              <div className='lead-address-box'>
                <AddressBox leadVals={leadVals} />
              </div>

            </div>

            <div className='lead-right'>
              <div className='lead-photo-holder'>
                <LeadPhoto leadVals={leadVals} />
              </div>

              <div className='lead-history-holder'>
                <LeadHistory leadVals={leadVals} />
              </div>
            </div>
          </div>

        </div>
      </div>

    );
  }
  
  function NoteAttachments({attachments}){
//    console.log(attachments);
    return(
      <div>
        {
          attachments.map( (a,n) => {
            return(<FileLink key={n} name={a}/>)
          })
        }
      </div>
      )
  }

  function LeadNotes({leadVals}){
    return(
      <div className = 'lead-notes-envelope'>
        {
          ('notes' in leadVals && leadVals.notes.map((note,num) => {
            return(
              <div className='lead-note-holder' key={num}>
                <div className='lead-title'>
                  <p> {note.noteType} </p>
                </div>

                <div className='lead-note-text'>
                  <p> {note.noteText}</p>
                </div>

                <div className='lead-note-attachments-holder'>
                  <NoteAttachments attachments={
                      "noteAttachments" in note && 
                      (note.noteAttachments.length > 0)? 
                      note.noteAttachments : 
                      []
                    }
                  />
                </div>

              </div>
            )
          }))
        }
      </div>
    );
  }
  
  function LeadAddNoteButton({leadVals, noteVals, setNoteVals}){
    return(
      <div className='hyozan-form-button-holder'>
        <button  className='hyozan-form-submit-button hyozan-button secondary' 
            onClick={()=>{setNoteVals({...noteVals, notePresent:true})}}>
              Add Note
        </button>
      </div>
    );
  }  

  function LeadButtons({leadVals}){    

    function Close({leadVals}){
      return(
        <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-cancel-button hyozan-button cancel' 
              onClick={closeHandler}>
                Close
          </button>
        </div>
      );
    }
    function AssignToMe({leadVals}){
      return(
        <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-submit-button hyozan-button active' 
              onClick={(e)=>  {
                        e.preventDefault(); 
                        db.setLeadOwner(leadVals.leadID,leadVals.leadID, closeHandler);     // Back end will assign the lead to me                        
                      }
                  }
          >
                Assign to me
          </button>
        </div>
      );      
    };
    // TODO - Sales manager can assign lead to other users...

    function Won({leadVals}){
      return(
        <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-submit-button hyozan-button primary' 
              onClick={(e)=>{
                        e.preventDefault(); 
                        if(window.confirm("Congratulations! \n This lead will be marked as won and assigned to you!")){
                          db.setLeadStatus(leadVals.leadID,'CLOSED',closeHandler);
                        }
                      }
                  }
          >
                Won
          </button>
        </div>
      );      
    }

    function Contacted({leadVals}){
      return(
        <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-submit-button hyozan-button primary' 
              onClick={(e)=>{
                        e.preventDefault(); 
                        db.setLeadStatus(leadVals.leadID,'CONTACTED',closeHandler);
                      }
                  }
          >
                Contacted
          </button>
        </div>
      );      
    }

    function NotContacted({leadVals}){
      return(
        <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-submit-button hyozan-button primary' 
              onClick={(e)=>{
                      e.preventDefault(); 
                      db.setLeadStatus(leadVals.leadID,'NOTCONTACTABLE',closeHandler);
                      }
                  }
          >
                NotContacted
          </button>
        </div>
      );      
    }

    function Lost({leadVals}){
      return(
        <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-submit-button hyozan-button primary' 
              onClick={(e)=>{
                        e.preventDefault(); 
                        if(window.confirm("Oh No 😔 \n This lead will be marked as lost!")){
                          db.setLeadStatus(leadVals.leadID,'LOST',closeHandler);
                        }
                      }
                  }
          >
                Lost
          </button>
        </div>
      );      
    }
    
    function Warm({leadVals}){
      return(
        <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-submit-button hyozan-button primary' 
              onClick={(e)=>{
                        e.preventDefault(); 
                        db.setLeadStatus(leadVals.leadID,'WARM',closeHandler);
                      }
                  }
          >
                Warm
          </button>
        </div>
      );      
    }

    function Cold({leadVals}){
      return(
        <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-submit-button hyozan-button primary' 
              onClick={(e)=>{
                        e.preventDefault(); 
                        db.setLeadStatus(leadVals.leadID,'COLD',closeHandler);
                      }
                  }
          >
                Cold
          </button>
        </div>
      );      
    }


    // State machine to handle leads...
    const mainAction = {
      INITIAL:        [AssignToMe, Close],               // All leads start in this state
      QUALIFIED:      [AssignToMe, Close],               // System or human has added some metadata to qualify this lead.
      ASSIGNED:       [Won, Contacted, NotContacted, Lost, Close],   // Sales person is working on the lead.-> Most leads will be here?
      CONTACTED:      [Won, Warm, Cold, Lost, Close],        // Customer was contacted
      NOTCONTACTABLE: [Contacted, Cold, Lost, Close],        // Customer could not be contacted
      COLD:           [Contacted, Lost, Close],             // This lead is not interested
      WARM:           [Won, Close],                         // Warm lead
      WON:            [Close],                              // Victory - lead was converted.
      STALE:          [Contacted, Close],                   // Hasnt been touched in a long time
      CLOSED:         [Close],                              // Lost
      LOST:           [Close],                              // Lost
    }
    const buttonList = mainAction[leadVals.disposition];

    return (
      <div className='hyozan-form-button-area'>
        {buttonList.map((Btn,i) => {return( <Btn key={i} leadVals={leadVals} />) })}
      </div>
    )     
  }
}

