import './css/Productivity.css'

import { useEffect, useState } from 'react';

import HyozanForm from './HyozanForm';
import DBCache from './DBCache';
import CommonValidations from './Validations';
import Waiter from './Waiter';
import BubbleChart from './BubbleChart';

const leadStatusVals = [
  'INITIAL',
  'ASSIGNED',
  'CONTACTED',
  'WARM',
  'CONVERTED',
  'LOST',
  'COLD',
  'NOTCONTACTABLE',
];

export default function Productivity(){

  const [productivityData, setProductivityData] = useState([]);
  const [waiting, setWaiting]       = useState(false);

  const db = new DBCache();
  const cv = new CommonValidations();

  const formData = {
    startDate: '01/01/2023',
    endDate: '10/10/2023',
    formName: 'hyozan-form-hero'
  };

  const vals={
    FormName: 'Choose Leads Filters',
    Fields:[
      {
        Label: 'Start Date',
        Explanation: 'Choose the start date for lead data ',
        InputType: 'DATE',
        onChange: handleChange,
        Prompt:{
          Type: 'INFO',
          Label: 'Please enter start date for lead data '
        },
        Value: FormData.startDate,
        ID: 'startDate',
        InitValue : '2024-01-10'    // TODO - Compute this to beginning of this month
      },
      {
        Label: 'End Date',
        Explanation: 'Enter the end date for lead data',
        InputType: 'DATE',
        onChange: handleChange,
        Prompt: {
          Type: 'INFO',
          Label: 'Please enter end date for lead data...'
        },
        Value: FormData.endDate,
        ID: 'endDate',
        InitValue: '2024-01-11'     // TODO - Compute this to be yesterday
      },
      {
        Label: 'Forms',
        Explanation: 'Choose which forms you want lead info',
        InputType: 'LIST',
        Options: [
          {
            ID: 'hyozan-form-hero',
            Text: 'home page hero form'
          },
          {
            ID: 'hyozan-form-cards',
            Text: 'cards lead form'
          }
        ],
        onChange: handleChange,
        Prompt: {
          Type: 'INFO',
          Label: 'Choose forms'
        },
        ID: 'formName',
        InitValue: null
      }
    ],
    onSubmit: handleSubmit,
    onCancel: handleCancel,
    SubmitText: 'View Productivity'
  };

  function handleChange(e){}

  function handleSubmit(vals){

    var lStartDate = cv.yyyymmdd2Date(vals.startDate);
    var lEndDate   = cv.yyyymmdd2Date(vals.endDate);
    
    /*** only for quick debug **/
    if(lStartDate === null) 
      lStartDate = cv.ddmmyyyy2Date('01/01/2023');
    if(lEndDate === null)
      lEndDate = cv.ddmmyyyy2Date('01/01/2025');
    /*** end debug **/

    if ((lStartDate  != null) &&  (lEndDate != null)) {
      const fieldvals = {
        startDate: lStartDate,
        endDate  : lEndDate,
        formName : vals.formName
      }
      setWaiting(true);
      db.getProductivity(fieldvals, callback);
    }
  }

  function callback(data){
    // Reshape the data ..
    // We need something like
    // staffList = {
    //    'john doe': {
    //      ASSIGNED: {number: 123, duration: 2.4, cumulativeTime: 3.5},
    //      CONTACTED: {number: 34, duration: 4.1, cumulativeTime: 4.3},
    //      ...
    //    },
    //    'jane doe': {
    //    },
    // }
    //
    const staffList = {};
    data.map((r) => {
      const staffName       = r._id.leadOwner;
      const disposition     = r._id.disposition;
      const activity        = {
                                number:   r.number,
                                duration: r.duration,
                                cumulative: r.cumulativeTime,
                              };
      if (!staffList[staffName]){
        staffList[staffName] = {name: staffName};
        staffList[staffName][disposition] = activity;
      }
      else
        staffList[staffName][disposition]  = activity;
    });
    setWaiting(false);
    setProductivityData(staffList);
  }

  function handleCancel(){
    setProductivityData([]);
  }

  return(
    <div className='hyozan-workspace'>
      {waiting && <Waiter />}
      <HyozanForm FormVals={vals} />
      <ProductivityView data={productivityData}/>
    </div>
  )

  function ProductivityTable({data, type}){
    return(
      <div className='hyozan-dashboard'>
        <div className='dashboard-header'>
          <div className='dashboard-title'>
            <p> Productivity Analytics </p>
          </div>
        </div>

        <div className='dashboard-spacer'></div>
        <table className='hyozan-table'>
          <ProductivityHeader type={type}/> 
          <tbody>
            {Object.keys(data).map((staff) => {return(
               <ProductivityRow key={staff.replaceAll(' ','_')} staff={data[staff]} type={type}/>
            )})
            }
            <ProductivitySummary data={data} type={type}/>
          </tbody>
        </table>
      </div>
    )
  }

  function ViewButtons({handler}){
    return(
      <div className='lead-buttons-holder'>
        <div className='hyozan-form-button-area lead-buttons-holder'>
          <div onClick={()=>{handler('BUBBLE')}} className='hyozan-form-button-holder'>
            <button className='hyozan-form-submit-button hyozan-button active'> Bubble </button>
          </div>

          <div onClick={()=>{handler('SIZETABLE')}} className='hyozan-form-button-holder'>
            <button className='hyozan-form-submit-button hyozan-button active'> Numbers </button>
          </div>

          <div onClick={()=>{handler('TIMETABLE')}} className='hyozan-form-button-holder'>
            <button className='hyozan-form-submit-button hyozan-button active'> Duration </button>
          </div>

        </div>
      </div>
    );
  }

  function ProductivityBubble({data}){
    const [bubbleData, setBubbleData] = useState([]);
    const [activeBubble, setActiveBubble] = useState(-1);
    const bubbles = [];
    let   active  = null;
    if (bubbleData.length === 0 ){
      Object.keys(data).map((name)=>{
       if (data[name].hasOwnProperty('CONVERTED')){
          const d     = data[name];
          const size  = d.ASSIGNED.number;
          const y     = d.CONVERTED ? d.CONVERTED.number / size : 0;
          const x     = d.CONVERTED ? d.CONVERTED.cumulative : 0;
          const hue   = Math.floor(Math.random()* 359);
          const color = `hsl(${hue}, 60%, 60%)`;
          const number = d.CONVERTED.number;
          const cumulative = d.CONVERTED.cumulative;

          const bubble = {
            label: name,
            size: size,
            y: y,
            x: x,
            color: color,
          };
          bubbles.push(bubble);
        }
      });
      setBubbleData(bubbles);
    }

    if (activeBubble >= 0)
      active = data[bubbleData[activeBubble].label??0];

    return(
      <div className='productivity-bubble-holder'>
        <BubbleChart 
          data={bubbleData} 
          width={800} 
          height={400} 
          yLabel={'Conversion Rate'} 
          xLabel={'Conversion(days)'}
          onActive={(b)=>{setActiveBubble(b)}}
        />
        <div className='productivity-bubble-details'>
          {activeBubble >= 0 &&
            <div className='bubble-name'>
              <span>{active.name}</span> 
              <span> has converted {active.CONVERTED.number??0} leads out 
                of {active.ASSIGNED.number??'-'} assigned to them.
              Their overall conversion efficiency 
              was {Math.floor(((active.CONVERTED.number)/ (active.ASSIGNED.number))*100.0)}% and they took
              an average of {active.CONVERTED.cumulative.toFixed(2)} days to make this conversion.</span>

              <p> Pipeline </p>
              <table className='bubble-stats-table hyozan-table'>
                <thead className='hyozan-table-header'>
                  <tr className='hyozan-table-header-tr'>
                    <th> Stage </th>
                    <th> Num </th>
                    <th> Days   </th>
                    <th> Tot    </th>
                  </tr>
                </thead>

                {Object.keys(active).map((k)=> {
                  if(k !== 'name')
                    return(
                      <tr key={k.name}>
                        <td> {k} </td>
                        <td> {active[k].number} </td>
                        <td> {active[k].duration.toFixed(1)} </td>
                        <td> {parseFloat(active[k].cumulative).toFixed(1)} </td>
                      </tr>
                    )
                  })
                }
              </table>
            </div>
          }
          {activeBubble < 0 &&
            <div className='bubble-name'>
              <p> mouse over or touch a bubble to see more info </p>
            </div>
          }

        </div>
      </div>
    )
  }

  function ProductivityView({data}){
    const [viewType, setViewType] = useState('TABLE');
    var component = null;

    if (data.length <= 0)
      return;

    switch(viewType){
      case 'BUBBLE':
        component = (<ProductivityBubble data={data} />);
        break;
      case 'SIZETABLE':
      default:
        component = (<ProductivityTable data={data} type={viewType}/>);
        break;
    }
    return (
      <div>
        <ViewButtons handler={setViewType} />
        {component}
      </div>
    )
  }

  function makeTotals(data){
    
    const totals = {};
    let   grandTotal = 0;
    leadStatusVals.map((l)=>{totals[l]=0});
    Object.keys(data).map((staff)=>{
      leadStatusVals.map((k)=>{
        if (data[staff].hasOwnProperty(k))
          totals[k] += data[staff][k].number ?? 0;
      });
    });
    
    Object.keys(totals).map((k) => {
      grandTotal += totals[k];
    });

    return([totals,grandTotal]);

  }

  function wtdAverages(data){
    const totals = {};
    const accumulator = {};
    let grandTotal=0.0;

    leadStatusVals.map((l) => {
      totals[l] = 0.0;
      accumulator[l] = { number: 0, totalTime: 0};
    });

    Object.keys(data).map((staff,i) => {
      const s = data[staff];
        leadStatusVals.map((d) => {
          if(s.hasOwnProperty(d)){
            const rec = s[d];
            accumulator[d].number += rec.number ?? 0;
            accumulator[d].totalTime += ((rec.duration * rec.number) ?? 0);
          }
        });
    });

    leadStatusVals.map((l) => {
      const val = accumulator[l].totalTime / accumulator[l].number ?? '-';
      totals[l] = Math.floor(val * 10)/10;
    });

    let totNum = 0;
    let totTime = 0.0;
    Object.keys(accumulator).map((i) => {
      const a = accumulator[i];
      totNum += a.number;
      totTime += a.totalTime;
    });

    grandTotal = Math.floor(((totTime / totNum) *10)/10);

    return [totals, grandTotal];

  }


  function ProductivitySummary({data, type}){
    let [totals, grandTotal] = [[], 0.0];
    switch(type){
      case 'TIMETABLE':
        [totals, grandTotal] = wtdAverages(data);
        break;
      case 'NUMBER':
      default:
        [totals, grandTotal] = makeTotals(data);
    }
    
    return (
      <tr className='analytics-totals' key='12345' >
        <td>{`Total: ${grandTotal}`}</td>
        <td>{totals.ASSIGNED}</td>
        <td>{totals.CONTACTED}</td>
        <td>{totals.WARM}</td>
        <td>{totals.CONVERTED}</td>
        <td>{totals.LOST}</td>
        <td>{totals.COLD}</td>
        <td>{totals.NOTCONTACTABLE}</td>
      </tr>
    )
  
  }


  function ProductivityHeader({type}){
    return (
      <thead className='hyozan-table-header'>
        <tr className='hyozan-table-header-tr'>
          <th> Name</th>
          <th> Assigned</th>
          <th> Contacted</th>
          <th> Warm</th>
          <th> Converted</th>          
          <th> Lost</th>          
          <th> Cold</th>          
          <th> Not<br/>contactable</th>          
        </tr>
      </thead>
    );
  }

  function ProductivityRow({staff, type}){

    function makestat(s,val,type){
      let field = 'number';

      switch(type){
        case 'TIMETABLE':
          field = 'duration';
          break;
        case 'NUMBER':
        default:
          field = 'number';
      }
      const retval = `${s[val]? s[val][field] ?? '-' : '-'}`;
      if (type === 'TIMETABLE')
        return( (retval != '-') ? Math.floor(retval * 10)/10 : retval);
      else
        return retval;
    }

    const s = staff;
    const assigned  = makestat(s,'ASSIGNED',type);
    const contacted = makestat(s,'CONTACTED',type);
    const warm      = makestat(s,'WARM',type);
    const converted = makestat(s,'CONVERTED',type);
    const lost      = makestat(s,'LOST',type);
    const cold      = makestat(s,'COLD',type);
    const notcontact= makestat(s,'NOTCONTACTABLE',type);

    return (
      <tr key={s.name.replaceAll(' ','_')} >
        <td>{s.name}</td>
        <td>{assigned}</td>
        <td>{contacted}</td>
        <td>{warm}</td>
        <td>{converted}</td>
        <td>{lost}</td>
        <td>{cold}</td>
        <td>{notcontact}</td>
      </tr>
    )
  }
}
