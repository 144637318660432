/**
 * 
 * Common Validation Functions, to be used across client and server side.
 * 
 * 
 */

class  CommonValidations {
  static initialized=false;
  constructor(){
    CommonValidations.initialized = true;
    // Nothing specific. Will mostly use this at class level
  }

  validateSiteName(name){
    // Regular expression to validate domain name
    const domainRegex = /^(?!:\/\/)(?:(?:[a-zA-Z][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63})(?::\d{1,5})?(?:[?/#]\S*)?$/;
    
    return domainRegex.test(name);
  }

  validateDescription(desc) {
   if (typeof desc !== 'string') {
    return false;
   }
   if (desc.length > 255) {
    return false;
   }
   if (/[&<>"'/]/.test(desc)) {
     return false;
   }

    return true;
  }

  getUniqueID(){
    // Generate a unique alphanumeric string
    // that can be used for further processing etc
    //
    const ids= btoa(Math.floor(Math.random()*1000000))+btoa(Date.now());

    return(ids.replace(/=/g,'_'));
  }

  DDBUnmarshall(inval, recursionlevel=0) {
    // Workaround for DynamoDB behavior of 
    // prefixing S / SS etc on all objects.

    // The AWS convert utility is not working 
    // for deeply nested objects hence this utility.


    // console.log("DDBUnmarshall Converting " + JSON.stringify(inval) + " Recursion level " + recursionlevel);

    if (typeof(inval) != 'object')
      return inval;

    const outval = {};

    const entries = Object.entries(inval);
    var key;
    var val;

    for (let i=0; i < entries.length; i++)
    {
        key = entries[i][0];
        val = entries[i][1];
        // console.log("Index " + i + " Key:" + key + " val:"+ JSON.stringify(val));
        switch(key){
            case 'S':
            case 'N':
            case 'B':
            case 'BOOL':
                // console.log("returning ", val);
                return val;
                break;
            case 'SS':
                // console.log("SS returning ", val)
                return val;
                break;
            case 'L':
              // L indicates an array of objects.
              const outArr=[];
              for(let k=0; k < val.length; k++){ // Use val as an array
                outArr.push(this.DDBUnmarshall(val[k], recursionlevel+1));
              } 
              // console.log("L Returning ", outArr)
              return outArr;
            case 'M':
              // console.log("M returning ", val);
              return this.DDBUnmarshall(val, recursionlevel+1);
            default:
                outval[key] = this.DDBUnmarshall(val, recursionlevel +1 );
                break;
        }
    }    
    //  console.log("Returning");
    //  console.log(outval);
    return outval;
  }

  formattedDate(dateString) {
    
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
    
  }

  ddmmyyyy2Date(dateString){ 
    try{
      // Regular expression to validate the date format
      const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
      const match = dateString.match(regex);

      if (!match) {
        throw new Error("Invalid date format. Please use dd/mm/yyyy.");
      }

      // Extract day, month, and year from the date string
      const day = parseInt(match[1], 10);
      const month = parseInt(match[2], 10) - 1; // Months are 0-based in JavaScript Date object
      const year = parseInt(match[3], 10);

      // Create a Date object
      const date = new Date(year, month, day);

      // Validate that the date object matches the input date
      if (date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day) {
        throw new Error("Invalid date. Please ensure the day, month, and year are correct.");
      }

      // Convert to Unix timestamp (milliseconds since epoch)
      const unixTimestamp = date.getTime();

      return unixTimestamp;

    }catch(err){
      console.log(err);
      return null;
    }
  }

  yyyymmdd2Date(dateString){ 
    try{
      // Regular expression to validate the date format
      const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
      const match = dateString.match(regex);

      if (!match) {
        throw new Error("Invalid date format. Please use yyyy-mm-dd.");
      }

      // Extract day, month, and year from the date string
      const day = parseInt(match[3], 10);
      const month = parseInt(match[2], 10) - 1; // Months are 0-based in JavaScript Date object
      const year = parseInt(match[1], 10);

      // Create a Date object
      const date = new Date(year, month, day);

      // Validate that the date object matches the input date
      if (date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day) {
        throw new Error("Invalid date. Please ensure the day, month, and year are correct.");
      }

      // Convert to Unix timestamp (milliseconds since epoch)
      const unixTimestamp = date.getTime();

      return unixTimestamp;

    }catch(err){
      console.log(err);
      return null;
    }
  }
}

export default CommonValidations;

function validateAndConvertDate(dateString) {
  // Regular expression to validate the date format
  const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  const match = dateString.match(regex);

  if (!match) {
    throw new Error("Invalid date format. Please use dd/mm/yyyy.");
  }

  // Extract day, month, and year from the date string
  const day = parseInt(match[1], 10);
  const month = parseInt(match[2], 10) - 1; // Months are 0-based in JavaScript Date object
  const year = parseInt(match[3], 10);

  // Create a Date object
  const date = new Date(year, month, day);

  // Validate that the date object matches the input date
  if (date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day) {
    throw new Error("Invalid date. Please ensure the day, month, and year are correct.");
  }

  // Convert to Unix timestamp (milliseconds since epoch)
  const unixTimestamp = date.getTime();

  return unixTimestamp;
}

// Example usage:
try {
  const dateString = "10/01/2024";
  const unixTimestamp = validateAndConvertDate(dateString);
  //console.log(`Unix Timestamp: ${unixTimestamp}`);
} catch (error) {
  console.error(error.message);
}





