import './css/App.css';
import Workspace  from './Workspace';
import HeaderBar from './Header';
import Sidebar from './Sidebar';
import Footer  from './Footer';

import { useState } from 'react';

// import {withAuthenticator} from '@aws-amplify/ui-react';
import { AppProvider } from './Context';

const menulist=[
  {description: 'Home' ,        menuItem: 'Home',             type: 'link'},
  {description: 'Content',      menuItem: null,               type: 'head'},
  {description: 'Sites' ,       menuItem: 'SitesMenu',        type: 'link'},
  {description: 'Jobs'  ,       menuItem: 'JobsMenu',         type: 'link'},
  {description: 'History' ,     menuItem: 'HistoryMenu',      type: 'link'},

  {description: 'Sales',        menuItem: null,               type: 'head'},
  {description: 'Lead Search',  menuItem: 'LeadSearch',       type: 'link'},
  {description: 'Leads List',   menuItem: 'LeadsMenu',        type: 'link'},
  {description: 'Lead Flow' ,   menuItem: 'LeadsFlow',        type: 'link'},
  {description: 'Productivity', menuItem: 'LeadsProductivity',type: 'link'},

  {description: 'Analytics',    menuItem: null,               type: 'head'},
  {description: 'Logs' ,        menuItem: 'LogsMenu',         type: 'link'},
  {description: 'Issues'  ,     menuItem: 'IssuesMenu',       type: 'link'},
  {description: 'Tagging' ,     menuItem: 'TaggingMenu',      type: 'link'},
  {description: 'Analytics' ,   menuItem: 'AnalyticsMenu',    type: 'link'},

  {description: 'Manage',       menuItem: null,               type: 'head'},
  {description: 'Users' ,       menuItem: 'UsersMenu',        type: 'link'},
  {description: 'Departments',  menuItem: 'DeptsMenu',        type: 'link'},
  {description: 'Roles' ,       menuItem: 'RolesMenu',        type: 'link'},
  {description: 'Permissions' , menuItem: 'PermissionsMenu',  type: 'link'},
  {description: 'Workflows' ,   menuItem: 'WorkflowsMenu',    type: 'link'},

];

function App() {
  const [menuItem, setMenuItem] = useState('HomeMenu');
  
  return (
    <AppProvider>
      <div className="App">
        <HeaderBar />
        <div className='bodyclass'>
          <Sidebar className='sidebar' menulist={menulist} callbackFn={setMenuItem}/>
          <Workspace className="workspace" menuItem={menuItem}/>
        </div>
        <Footer className='footer' />
      </div>
    </AppProvider>
  );
}

// export default withAuthenticator(App);
export default App;
